@use '0_utility/variables/colors';

.modal {
	bottom: 0;
	color: colors.$black;
	display: block;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;

	&:not(.is-open) {
		display: none;
	}

	&__overlay {
		align-items: center;
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	&__container {
		background: colors.$white;
		border-radius: 20px;
		display: block;
		height: 40vh;
		width: 40vw;
	}

	&--full-screen {
		.modal__container {
			border-radius: 0;
			height: 100%;
			width: 100%;
		}
	}
}
