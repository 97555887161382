@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-weights';

.contact {
	color: colors.$greenDark;
	&-link {
		display: block;
		padding-block: 7rem 4rem;
		text-align: center;
		@include media-query.up(md) {
			align-items: center;
			display: flex;
			height: 70vh;
			min-height: 60rem;
			text-align: left;
		}
	}
	&-grid {
		@include media-query.up(md) {
			display: grid;
			grid-template: {
				columns: 1fr 30%;
				rows: auto 1fr;
			}
		}
	}
	&-title {
		font-size: 1.4rem;
		@include media-query.up(md) {
			font-size: 1.8rem;
		}
	}
	&-invited {
		font: {
			size: 10rem;
			weight: font-weights.$bold;
		}
		@supports (font-size: clamp(6rem, 15vw, 19rem)) {
			font-size: clamp(7.7rem, 15vw, 20rem);
		}
		line-height: 1;
		margin-block: 0 2rem;
		@include media-query.up(md) {
			grid: {
				column: 1 / span 2;
				row: 2;
			}
			margin-block: 0;
			z-index: 2;
		}
	}
	&-image {
		box-shadow: -3rem -3rem rgba(colors.$white, .38);
		margin: {
			left: 3rem;
			top: 5rem;
		}
		@include media-query.up(md) {
			box-shadow: -1.8rem -1.8rem rgba(colors.$white, .38);
			grid: {
				column: 2;
				row: 1 / span 2;
			}
			margin: 0;
		}
		img {
			width: 100%;
		}
	}
}
