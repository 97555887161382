@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.button {
	align-items: center;
	background: colors.$green;
	border: 1px solid currentColor;
	border-radius: 100%;
	color: colors.$black;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 1.8rem;
	font-weight: 600;
	height: 20rem;
	justify-content: center;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
	width: 20rem;

	&[disabled] {
		background: colors.$grayDark;
		border-color: colors.$grayDark;
		color: colors.$gray;
		cursor: not-allowed !important;
	}

	&:active,
	&:focus,
	&:hover {
		&:not([disabled]) {
			background: colors.$white;
			color: colors.$black;
		}
	}

	.icon {
		display: block;
		height: 1em;
		width: 1em;

		svg {
			fill: currentColor;
			width: 100%;
		}
	}
}
