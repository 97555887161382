@use '0_utility/variables/colors';

.image-scale {
	position: relative;
	&::after {
		background-color: colors.$white;
		content: '';
		display: block;
		inset: 50% 0 0;
		position: absolute;
		z-index: -1;
	}
	figure {
		margin-inline: auto;
		transition: width 600ms ease-in-out;
		width: 50%;
		@at-root .animate#{&} {
			width: 100%;
		}
	}
	img {
		width: 100%;
	}
}
