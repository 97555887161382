@use '0_utility/mixins/media-query';
@use '0_utility/mixins/text-stroke';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-sizes';
@use '0_utility/variables/font-weights';
@use '0_utility/variables/spacing';
@use 'sass:list';
@use 'sass:meta';

@custom-selector :--heading h1,
h2,
h3,
h4,
h5,
h6;

:--heading,
p {
	margin-block: 0;
}

:--heading {
	line-height: 1.1;
}

@each $title, $size in font-sizes.$titles {
	.title-#{$title} {
		font-size: list.nth($size, 1);
		@if list.length($size) > 1 {
			@include media-query.up(md) {
				font-size: list.nth($size, 2);
			}
		}
	}
}

h1 {
	font: {
		size: 4rem;
		weight: 600;
	}
	@include media-query.up(md) {
		font-size: 6rem;
	}
}

h2 {
	font: {
		size: 6rem;
	}
}

h3 {
	font: {
		size: 2.2rem;
		weight: font-weights.$medium;
	}
}

p {
	:--heading + &,
	& + & {
		margin-top: spacing.$paragraph;
	}
}

@if meta.global-variable-exists(textColors, $module: 'colors') {
	@each $name, $color in colors.$textColors {
		.text-#{$name} {
			color: $color;
		}
	}
}

.text-stroke {
	@include text-stroke.stroke($width: 0.5px);

	&--green {
		@include text-stroke.stroke($color: colors.$green, $width: 0.5px);
	}
}

.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
