@use "0_utility/mixins/media-query";
@use "0_utility/variables/colors";

// this class is applied to the container element which the cursor should show over
.custom-cursor {
	// make sure the actual cursor is hidden
	cursor: none !important;
}

// ********************************
// Cursors defined below
// ********************************
.cursor {
	display: none;
	@include media-query.up(md) {
		// <defaults>
		align-items: center;
		background: colors.$black;
		border: 1px solid rgba(255, 255, 255, 0.1);
		border-radius: 100%;
		color: colors.$white;
		display: flex;
		flex-direction: column;
		height: 129px;
		justify-content: center;
		left: 0;
		line-height: 1.125;
		opacity: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		text-align: center;
		transform: translate(-50%, -50%); // default to the middle of the cursor
		transition: opacity 0.15s ease-in;
		width: 129px;

		&,
		* {
			// disable pointer events for the cursor and all of its children
			cursor: none !important;
			pointer-events: none !important;
		}

		&--active {
			opacity: 1;
		}

		&__icon {
			font-size: 3.2rem;
		}

		&__primary-text {
			font-size: 1.8rem;
			font-weight: 600;
		}

		&__subtext {
			color: colors.$grayLight;
			font-size: 1.6rem;
			font-weight: 400;
		}
		// </defaults>

		&-contact {
			background: colors.$white;
			color: colors.$black;
			.icon {
				width: 1.8rem;
				transform: rotate(-45deg);
			}
		}

		&-cta {
			background: colors.$white;
			color: colors.$black;
			.icon {
				width: 1.8rem;
			}
		}

		// <nav>
		&-nav {
			height: 120px;
			width: 120px;

			&--work {
				background: rgba(colors.$green, 0.8);
			}

			&--services {
				background: rgba(colors.$brown, 0.8);
			}

			&--careers {
				background: rgba(colors.$white, 0.8);
			}

			&--contact {
				background: rgba(colors.$pink, 0.8);
			}
		}
		// </nav>

		// <carousel>
		&-carousel {
			background: colors.$green;
			color: colors.$black;
			flex-direction: row;
			justify-content: space-between;
			padding-inline: 1.4rem;
			.arrow {
				font-size: 0;
				width: 1rem;
			}
			.next {
				transform: rotate(180deg);
			}
			svg {
				width: 100%;
			}
		}
		// </carousel>

		// Services
		&-services {
			color: colors.$black;
			.icon {
				width: 1.8rem;
				transform: rotate(-45deg);
			}
			&-strategy,
			&-data {
				background-color: colors.$green;
			}
			&-creative {
				background-color: colors.$white;
			}
			&-optimization,
			&-automation,
			&-technology {
				background-color: colors.$white;
			}
		}
	}
}
