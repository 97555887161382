@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-weights';

.privacy {
  p {
    margin-top: 2rem !important;
  }
  h2 {
    font-size: 4rem !important;
    margin-bottom: 2rem;
  }
  h3 {
    margin-top: 2rem;
  }
  li {
    margin-bottom: 1rem;
  }
  td {
    padding: 1.5rem;
  }
  li a {
    text-decoration: underline;
    cursor: pointer !important;
  }
  a:hover {
    color: #15b587;
  }
}