@use '0_utility/variables/font-sizes';
@use '0_utility/variables/font-family';
@use '0_utility/variables/font-weights';
@use '0_utility/variables/colors';

* {
	&,
	&:before,
	*:after {
		box-sizing: border-box;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	}
}

html {
	color: colors.$text;
	font: {
		family: font-family.$default;
		size: 62.5%;
	}
	line-height: 1.4;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	background-color: colors.$black;
	font-size: font-sizes.$default;
	margin: 0;
}

/*
 * Remove text-shadow in selection highlight:
 */

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

/*
 * Remove default figure styles.
 */

figure {
	margin: 0;
}

/*
 * Remove default blockquote styles.
 */

blockquote {
	margin: 0;
}

cite {
	font-style: normal;
}

/**
 * Remove the gray background color from active links in IE 10.
 * Remove default color
 */

a {
	color: inherit;
	background-color: transparent;
	text-decoration: none;
	-webkit-text-decoration-skip: objects;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a:active,
a:hover,
a:focus {
	outline: 0;
}

a:focus-visible {
	outline: .3rem solid colors.$red;
	outline-offset: 5px;
}

button:focus-visible {
	outline: .3rem solid colors.$red;
	outline-offset: 5px;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

strong {
	font-weight: font-weights.$bold;
}

/**
 * Remove border when inside `a` element in IE 8/9/10.
 */

img {
	border-style: none;
	border: 0;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

[tabindex="-1"]:focus {
	outline: none;
}
