@keyframes walk {
	0% {
		transform: scaleX(1);
	}

	49.9999999% {
		transform: scaleX(1);
	}

	50% {
		transform: scaleX(-1);
	}

	100% {
		transform: scaleX(-1);
	}
}

.i-am-lost {
	background: none;
	border: none;
	color: transparent;
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0;
	margin: 0;
	height: 10px;
	width: 10px;
	font-size: 0;
}

.cursor.guide {
	height: 100px;
	width: 100px;
	background: none;
	border: 0;

	img {
		animation: walk 1s linear infinite;
		display: block;
		max-width: 100%;
	}
}
