@use '0_utility/mixins/media-query';

.page-header {
	padding-block: 9rem 3rem;
	@include media-query.up(md) {
		padding-block: 11rem 5rem;
	}
	&-description {
		margin-top: 2rem;
	}
	&.shadow-label {
		margin-bottom: 0;
	}
}