@use '../0_utility/mixins/media-query';
@use '../0_utility/variables/spacing';
@use 'sass:meta';
@use 'sass:list';

@if meta.global-variable-exists(sectionBlocks, $module: 'spacing') {
	@each $name, $sizes in spacing.$sectionBlocks {
		$infix: if($name == default, '', '-#{$name}');

		.section-block#{$infix} {
			@if list.length($sizes) > 1 {
				padding-block: list.nth($sizes, 1);
				@include media-query.up(md) {
					padding-block: list.nth($sizes, 2);
				}
			} @else {
				padding-block: $sizes;
			}
		}
	}
}
