@use '../0_utility/variables/colors';
@use '../0_utility/variables/spacing';

$spinner-size: spacing.$md;

.summit-rsvp {
    &__form {

        /* Form Overrides */
        &.form-container {
            background-color: colors.$brand-2-light;
            border-radius: spacing.$xs;
            padding: spacing.$lg;
            padding-bottom: spacing.$md;

            p {
                font-size: 14px;
                margin: spacing.$md 0;
            }
        }

        .form-control {
            border-radius: 0;
            color: colors.$black;
            height: spacing.$xxl;
        }

        .more-info:hover,
        #submit-button:hover {
            color: colors.$brand-2-light;
        }
    }

    .title-text-container {
        padding-bottom: 125px;
        padding-top: 172px;
    }
}

.title-text-container .eyebrow {
    margin-bottom: spacing.$md;
}

.event-details-container h3 {
    margin: spacing.$xl 0 spacing.$md;
}

.event-details-container li {
    margin: spacing.$default 0;
}

.responsive-img {
    height: auto;
    max-width: 1340px;
    width: 100%;
}

.contact {
    display: none;
}

.form-group {
    margin-bottom: spacing.$lg;
}

input:not([type="button"]):not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
    background: colors.$white;
    border: 0;
    color: inherit;
    font-size: 14px;
    font-family: inherit;
    margin: 0;
    outline: none;
    padding: 0 1rem;
    -webkit-text-fill-color: #000 !important;
}

.footer-spacer {
    background: colors.$greenLight;
    height: spacing.$lg;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: colors.$black;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: colors.$black;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: colors.$black;
}

button.submit {
    background-color: colors.$black;
    border-radius: 0;
    color: colors.$white;
    padding: spacing.$md spacing.$default;
    width: 100%;
}

button.more-info {
    margin-bottom: spacing.$lg;
}

.btn:hover,
.btn:focus,
.btn.focus {
    color: colors.$white;
    font-weight: 600;
    text-decoration: none;
}

@media (min-width: 768px) {
    .title-text-container {
        padding-left: spacing.$xxl;
    }

    .event-details-container {
        padding: 0 spacing.$xxl;
    }

    .form-container {
        float: right;
        max-width: 385px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -215px;
    }
}

@media (max-width: 767px) {}

/***** Spinner CSS ****/

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
    border-radius: 50%;
    border-right-color: transparent;
    border: 0.25em solid currentColor;
    display: inline-block;
    height: $spinner-size;
    vertical-align: text-bottom;
    width: $spinner-size;
}

.spinner-border-sm {
    border-width: 0.2em;
    height: 1.5rem;
    margin-right: 3px;
    width: 1.5rem;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: none;
        opacity: 1;
        transform: none;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: none;
        opacity: 1;
        transform: none;
    }
}

.spinner-grow {
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    height: $spinner-size;
    opacity: 0;
    vertical-align: text-bottom;
    width: $spinner-size;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}