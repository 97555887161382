// Spacing
$default: 1rem;
$xs: $default * .5;
$sm: $default * 1.5;
$md: $default * 2;
$lg: $default * 3;
$xl: $default * 4;
$xxl:$default * 5;
$applyBtn: $default * 12;

// Padding for wrapper
$gutterWidth: $md;

// Spacing for multiple paragraphs
$paragraph: $sm;

// Spacing for section blocks
// These can be used as either a single value
// Or with multiple values for mobile and browser spacing
// Example .section-block-sm, .section-block-lg, ect.
// Note: default does not get added to the end and will be .section-block
$sectionBlocks: (
	sm: $md,
	default: ($xl, $default * 6),
	lg: ($default * 6, $default * 8),
	xl: ($default * 8, $default * 12)
);

// Column Spacing
// Used with column mixin to create classes with media queries for 12 column layout.
// Remove it not needed
$max: 12;
$gap: $gutterWidth;
$multiple-gaps: ( // Use this for multiple gaps with media queries. Remove if not needed
	xs: $sm,
	lg: $gap
);
