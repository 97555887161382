@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-weights';

.case-study {
	&-header {
		h1 {
			font-size: 6rem;
			line-height: 0.9;
			@supports (font-size: clamp(6rem, 15vw, 19rem)) {
				font-size: clamp(3.2rem, 6vw, 7.6rem);
			}
		}
		&-content {
			align-self: center;
			padding-top: 7rem;
			@include media-query.up(md) {
				padding-block: 7rem;
			}
		}
		&-image {
			align-self: flex-end;
			width: 100%;
			img {
				width: 100%;
			}
		}
	}
	&-logo {
		align-items: center;
		display: flex;
		gap: 1rem;
		margin-bottom: 3rem;
	}
	&-stats {
		color: colors.$black;
		overflow: hidden;
		padding-inline: 1rem;
		@include media-query.up(md) {
			margin-top: -93px;
			padding-inline: 0;
		}
		.container {
			display: flex;
			justify-content: flex-end;
		}
		p {
			font-weight: font-weights.$bold;
		}
		.stats {
			aspect-ratio: 6 / 5;
			position: relative;
			text-align: center;
			width: 100%;
			@include media-query.up(md) {
				width: 600px;
			}
		}
		.stat {
			align-items: center;
			aspect-ratio: 1 / 1;
			border-radius: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			opacity: 0;
			position: absolute;
			transform: scale(0.6);
			transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
			@include media-query.up(md) {
				gap: 1rem;
			}
			@at-root .animate#{&} {
				opacity: 1;
				transform: scale(1);
			}
			@for $i from 2 through 6 {
				&:nth-child(#{$i}) {
					transition-delay: $i * 100ms;
				}
			}
		}
		.pink,
		.green {
			width: 50%;
			p {
				font-size: 3.2rem;
				@include media-query.up(md) {
					font-size: 4.4rem;
				}
			}
		}
		.pink {
			background-color: colors.$pink;
			left: 0;
			top: 20%;
		}
		.green {
			background-color: colors.$green;
			right: 0;
			top: 0;
		}
		.teal {
			bottom: 20%;
			background-color: colors.$teal;
			right: 25%;
			width: 25%;
			z-index: 1;
			p {
				font-size: 1.8rem;
				@include media-query.up(md) {
					font-size: 2.4rem;
				}
			}
		}
		.small {
			overflow: hidden;
			width: 33.333%;
			img {
				width: 100%;
			}
		}
		.image-1 {
			left: 28%;
			top: -1%;
		}
		.image-2 {
			bottom: 23%;
			right: -2%;
		}
		.image-3 {
			bottom: -3%;
			left: 28%;
		}
		.emoji {
			left: -3%;
			bottom: -2%;
		}
		small {
			font-size: 1.2rem;
			@include media-query.up(md) {
				font-size: 1.4rem;
			}
		}
	}
	&-services {
		.title-large {
			margin-bottom: 4rem;
		}
		.title-small {
			color: colors.$gold;
		}
		p {
			a {
				color: colors.$green;
				text-decoration: underline;
				&:hover {
					color: colors.$white;
				}
			}
		}
		.technologies-services {
			&-block {
				& + .technologies-services-block {
					margin-top: 2.2rem;
				}
			}
		}
		.service-pills {
			display: flex;
			flex-wrap: wrap;
			gap: 1.2rem;
			margin-top: 1.5rem;
		}
		.pill {
			align-items: center;
			border: .1rem solid colors.$green;
			border-radius: 3.2rem;
			color: colors.$green;
			display: flex;
			font-size: 1.2rem;
			padding: .5rem 1.6rem;
		}
	}
	&-content {
		.title-large {
			margin-bottom: 4rem;
		}
	}
	&-carousel {
		&-header {
			text-align: center;
			margin-bottom: 5rem;
			@include media-query.up(md) {
				padding-inline: 5rem;
			}
		}
		.glide {
			@include media-query.up(md) {
				max-width: 75vw;
				margin-inline: auto;
			}
			@include media-query.up(lg) {
				max-width: 81.7rem;
			}
			&__arrows {
				display: flex;
				justify-content: center;
				gap: 2rem;
				margin-top: 1.5rem;
				@include media-query.up(md) {
					left: -7rem;
					justify-content: space-between;
					right: -7rem;
					margin-top: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				button {
					align-items: center;
					background: none;
					border: 0.2rem solid colors.$green;
					border-radius: 50%;
					cursor: pointer;
					display: flex;
					height: 3.5rem;
					justify-content: center;
					width: 3.5rem;
					@include media-query.up(md) {
						height: 6rem;
						width: 6rem;
					}
				}
				.previous {
					span {
						transform: rotate(180deg);
					}
				}
				span {
					font-size: 0;
					width: 1.5rem;
					@include media-query.up(md) {
						width: 2.3rem;
					}
				}
				svg {
					fill: colors.$green;
				}
			}
			&__pagination {
				display: flex;
				gap: 0.4rem;
				justify-content: center;
				margin-top: 5rem;
				span {
					border: 0.1rem solid colors.$black;
					border-radius: 50%;
					height: 0.5rem;
					transition: background-color 300ms ease-in-out;
					width: 0.5rem;
				}
				.glide__bullet--active {
					background-color: colors.$black;
				}
			}
		}
	}
}
