@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@import '@glidejs/glide/src/assets/sass/glide.core';
@import '@glidejs/glide/src/assets/sass/glide.theme';

.glide {
	&--marquee {
		pointer-events: none;
		.glide { // inner namespace
			&__slide {
				align-self: stretch;
				display: flex;
				height: auto;
			}
		}
	}
	&--hero {
		.glide { // inner namespace
			&__slide {
				display: flex;
				height: auto;
				transform: scale(0.95);
				transition: transform 0.4s ease-in-out;
				&--active {
					transform: scale(1);
				}
			}
		}
		.card {
			display: flex;
			flex-direction: column;
			&__content {
				align-items: flex-start;
				display: flex;
				flex: 1;
				flex-direction: column;
			}
			.cta {
				margin-top: auto;
			}
		}
	}
}
