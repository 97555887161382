@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';

.hero {
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	padding-bottom: 8rem;
	@include media-query.up(md) {
		&:not(.no-bottom-padding) {
			padding-bottom: 12rem;
		}
	}
	&-banner {
		position: relative;
		width: 100%;
	}
	&-main {
		&-image {
			background-color: colors.$black;
			width: 45%;
			margin-inline: auto;
			img {
				opacity: 0;
				transition: opacity .6s ease-in-out .4s;
				width: 100%;
				.fade-in & {
					opacity: .6;
				}
				&.fade-full {
					opacity: 1;
				}
			}
		}
		&-text {
			left: 0;
			position: absolute;
			top: 50%;
			text-align: center;
			transform: translate(0, -50%);
			width: 100%;
			.hero-title {
				&:not(.is-active) {
					opacity: 0;
				}
			}
		}
	}
	&-subtitle {
		margin-top: 1rem;
		p {
			margin-inline: auto;
			max-width: 47rem;
		}
	}
	&-backing-images {
		height: 70%;
		inset: 0;
		position: absolute;
		margin-block: auto;
		z-index: -1;
		.images-row {
			display: flex;
			inset: 0;
			justify-content: space-between;
			opacity: 0;
			transition: opacity .6s ease-in-out;
			position: absolute;
			.fade-in & {
				opacity: 1;
			}
		}
		.second-row {
			margin-inline: auto;
			width: 75%;
			transition-delay: .6s;
			.hero-image {
				flex: 0 0 42%;
			}
			img {
				opacity: .55;
			}
		}
		.third-row {
			height: 70%;
			margin: auto;
			transition-delay: .8s;
			.hero-image {
				flex: 0 0 22%;
			}
			img {
				opacity: .5;
			}
		}
		.hero-image {
			background-color: colors.$black;
		}
	}
}
