@use '0_utility/variables/colors';

@mixin stroke($color: colors.$white, $fbColor: $color, $width: 1px) {
	color: $fbColor;

	// if it's possible to use text-stroke, use that instead of solid text
	@supports (text-stroke: 1px white) or (-webkit-text-stroke: 1px white) {
		color: transparent;
		-webkit-text-stroke: $width $color;
		text-stroke: $width $color;
	}
}
