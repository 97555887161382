@use '../0_utility/mixins/media-query';
@use '../0_utility/variables/spacing';
@use 'sass:meta';
@use 'sass:map';
@use 'sass:math';

@if meta.global-variable-exists(max, $module: 'spacing') {
	.row {
		display: flex;
		&:not(.no-wrap) {
			flex-wrap: wrap;
		}
		@if meta.global-variable-exists(multiple-gaps, $module: 'spacing') {
			@each $size, $gap in spacing.$multiple-gaps {
				@include media-query.up($size) {
					$gutter: map.get(spacing.$multiple-gaps, $size);
					margin-inline: math.div($gutter, -2);
					row-gap: $gutter;
				}
			}
		} @else {
			margin-inline: math.div(spacing.$gap, -2);
			row-gap: spacing.$gap;
		}
	}
	.row-no-gutters {
		display: flex;
		
		&:not(.no-wrap) {
			flex-wrap: wrap;
		}
	}
}
