.icon {
	display: inline-block;
	width: 1em;

	svg {
		display: block;
		fill: currentColor;
		max-width: 100%;
		width: 100%;
	}
}
