@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.career--single{
    .background-white {
        padding-bottom: spacing.$xl;
    }
    .jd--block{
        margin: spacing.$xxl 0 spacing.$xxl;
        padding-right: 15%;
        h2{
            font-size: 2.4rem;
            margin-bottom: spacing.$lg;
        }
        p{
            font-size: 2rem;
            a:hover{
                color:colors.$green;
            }
        }
        ul{
            list-style: none;
            li{
                font-size: 1.8rem;
                line-height: 3rem;
                padding:spacing.$default 0;
                &::before{
                    content: "\2022";
                    color: colors.$pink;
                    display: inline-block;
                    font-weight: bold;
                    margin-left: -1em;
                    width: 1em;
                    font-size: 1.8rem;
                }
            }
        }
        @include media-query.down(md) {
            padding-right: inherit;
        }
    }
    .apply--btn{
        a{
            align-items: center;
            background-color: colors.$black;
            border: none;
            color:colors.$white;
            cursor: pointer;
            font-weight: 600;
            font-size: 2rem;
            padding: 1.5rem 5rem;
            text-align: center;
            transition: 0.3s;
            &:hover{
                background:colors.$white;
                color: colors.$black;
            }
        }
    }
    
	.single--job--box{
        background:colors.$greenLight;
        min-height: 200px;
        margin-top: -70%;
        overflow: hidden;
        padding: spacing.$default spacing.$md;
        position: sticky;
        top: 5%;
        
        ul{
            list-style: none;
            padding:0 spacing.$md;
            li{
                border-bottom: 1px solid colors.$greenGrey;
                color:colors.$black;
                font-style: normal;
                font-weight: 400;
                font-size: 2rem;
                line-height: 3.2rem;
                padding: spacing.$md spacing.$default;
                p + p{
                    margin-top: inherit;
                }
                h3{
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 2.9rem;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
        .apply--btn{
            margin:spacing.$xl auto;
            text-align: center;
            a{
                padding: spacing.$lg spacing.$applyBtn; 
                @include media-query.down(xl) {
                    padding: spacing.$sm spacing.$xxl; 
                }
            }
            
        }
        @include media-query.down(lg) {
            margin-top: spacing.$xl;
        }
        @include media-query.down(md) {
            margin-top: 0;
        }
        
    }
    @include media-query.down(md) {
        .article-hero {
            background-color:colors.$black;
            padding-bottom: spacing.$xxl;
        }
    }
}
