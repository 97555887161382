@use '0_utility/mixins/media-query';

.content-panel {
	& + & {
		margin-top: 6rem;
		@include media-query.up(md) {
			margin-top: 11rem;
		}
	}
	&__img {
		width: 100%;
	}
	&__content {
		.title-small {
			margin-bottom: 1rem;
		}
		@include media-query.up(md) {
			padding-inline: 10%;
		}
		@include media-query.up(lg) {
			padding-inline: 20%;
		}
	}
}
