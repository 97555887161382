@use '../0_utility/variables/colors';
@use '../0_utility/variables/spacing';
@use '../0_utility/variables/transition';

$spacer: spacing.$default * 2.5;
$rsvp-overlap: 28rem;

.flex {
    align-items: center;
    display: flex;
    justify-content: center;
}

.grid {
    display: grid;
    grid-gap: spacing.$xl;
    grid-template-columns: 1fr;
}

.column+.column {
    padding-top: $spacer;
}

.summit-details {
    overflow-x: hidden;

    .container {
        max-width: 120rem;
    }

    .hero {
        display: flex;
        flex-direction: column;
        padding: 0;

        h1 {
            margin-bottom: $spacer;
        }
    }

    .summit-logos {
        align-items: center;
        display: flex;
        margin-bottom: $spacer;
    }

    .col-text {
        @extend .flex;
    }

    .title-text-container {
        padding: 0;

        p {
            margin: 0 0 $spacer;
        }
    }
}

.pink-circle {
    float: left;
    height: spacing.$md;
    line-height: normal;
    margin-right: spacing.$default;
}

.underline-link {
    text-decoration: underline;
}

.bold-link-green {
    color: colors.$brand-2;
}

.rsvp-section {
    margin-top: 0;

    &__text {
        h3 {
            font-size: 3.8rem;
        }
    }

    &__image {
        align-items: center;
        display: flex;
        justify-content: center;

        .image-wrapper {
            height: 40rem;
            position: relative;
            width: 40rem;
        }

        .badge {
            align-items: center;
            display: flex;
            justify-content: center;
            position: absolute;

            &.sparkle {
                left: -4rem;
                top: 8rem;
            }

            &.star {
                right: 3rem;
                top: -1rem;

                .sparkles {
                    position: absolute;
                    right: 27%;
                    top: 33%;
                }
            }
        }
    }

    .row {
        align-items: center;
        height: auto;
        padding: $spacer;
    }
}

.rsvp-cta {
    @extend .flex;

    color: colors.$white;
    font-weight: bold;
    justify-content: center;
    margin-top: spacing.$md;
    width: auto;

    span {
        margin-right: spacing.$xs;
    }

    .icon {
        color: colors.$white;
        width: 4rem;

        circle {
            stroke: colors.$white;
        }

        path {
            stroke: colors.$black;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

.section-block.sessions {
    padding-left: spacing.$xl;
    padding-right: spacing.$xl;
}

.session-heading {
    margin-bottom: spacing.$xxl;
}

.session-details {
    border-top: 1px solid colors.$grayDark2;
    padding: spacing.$xxl 0;
    transition: transition.$all;

    &:hover {
        color: colors.$white;
        transform: scale(1.025);
    }

    &__header {
        font-size: 20px;
        font-weight: bold;
        padding: 0 0 spacing.$default;

        span {
            color: colors.$brand-1;
        }
    }

    .left {
        padding-right: spacing.$xxl;
    }

    h3 {
        color: colors.$white;
        font-size: 3rem;
    }

    h4 {
        color: colors.$white;
        padding: spacing.$md 0 spacing.$default;
    }

    ul {
        margin: 0;
        padding-left: spacing.$md;
    }

    a.speaker {
        color: colors.$brand-2;
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            color: colors.$brand-2-dark;
            text-decoration: none;
        }

    }

}

.cta-register {
    @extend .flex;
    color: colors.$white;
    font-weight: bold;
    height: spacing.$lg;
    justify-content: flex-start;
    margin-top: spacing.$lg;

    .icon {
        color: colors.$white;
        margin-left: spacing.$default;
        width: $spacer;

        circle {
            stroke: colors.$white;
        }

        path {
            stroke: colors.$black;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

.rsvp-heading {
    font-weight: bold;
    margin: 20px 0 24px 0;
}

.link-list {
    margin-left: 0;
}

.link-container {
    border-top: 1px solid colors.$navGray;
}

.rsvp-link {
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: normal;
    height: 8rem;
    justify-content: space-between;
}

.link-container:hover .icon-button {
    transform: scale(1.25);
}

.link-container:hover .icon-button svg {
    fill: colors.$grayLight;
}

.link-container .text {
    color: colors.$gray;
    font-weight: 600;
    transition: transition.$all;
}

.link-container:hover .text {
    color: colors.$black;
}

.rsvp-link .icon-button {
    align-items: center;
    display: flex;
    transform-origin: 50% 50%;
    transition: transition.$all;
}

.responsive-img {
    height: auto;
    max-width: 1340px;
    width: 100%;
}

.contact {
    display: none;
}

.footer-spacer {
    background: colors.$greenLight;
    height: spacing.$lg;
}

@media (min-width: 768px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }

    .column+.column {
        padding-top: 0;
    }

    .link-list {
        margin-left: spacing.$xl;
    }

    .title-text-container {
        padding-left: spacing.$xxl;
        padding-right: 10rem;
    }

    .title-img2 {
        margin-top: -120px;
    }

    .section-block.booth-info {
        padding-bottom: $rsvp-overlap + spacing.$xxl;
    }

    .rsvp-section {
        margin-top: -$rsvp-overlap;

        .row {
            padding: 10rem;
        }

        .rsvp-cta {
            justify-content: flex-start;
        }
    }

    .session-text {
        padding-right: 75px;
    }
}

@media (max-width: 767px) {
    .social-icons {
        margin-bottom: 0;
    }

    .rsvp-heading {
        margin: spacing.$md spacing.$default;
    }
}