// Basic font sizes
$small: 1.4rem;
$default: 1.8rem;
$large: 2.2rem;

// Title font sizes
$titles: (
	"small": $default,
	"medium": (
		2.2rem,
		2.8rem,
	),
	"large": (
		4rem,
		6rem,
	)
);
