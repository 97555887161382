@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-weights';

.quote-section {
	padding-top: 10rem;
	@include media-query.up(md) {
		padding-top: 15rem;
	}
	&-content {
		display: flex;
		flex-direction: column;
		@include media-query.up(md) {
			flex-direction: row;
			gap: 5rem;
		}
		&::before {
			color: colors.$green;
			content: open-quote;
			font-size: 15rem;
			line-height: .5;
			text-align: center;
			@include media-query.up(md) {
				font-size: 20rem;
			}
		}
		blockquote {
			display: flex;
			flex-direction: column;
			gap: 2.2rem;
			@supports (font-size: clamp(6rem, 15vw, 19rem)) {
				&::after {
					align-self: flex-end;
					color: rgba(colors.$white, .05);
					content: attr(data-shadow-text);
					font: {
						size: clamp(6rem, 15vw, 19rem);
						weight: font-weights.$bold;
					}
					line-height: 1;
				}
			}
		}
		p {
			font: {
				size: 3.2rem;
				weight: font-weights.$bold;
			}
			line-height: 1;
			@include media-query.up(md) {
				font-size: 4.2rem;
			}
			@include media-query.up(lg) {
				max-width: 70%;
			}
		}
		cite {
			align-items: center;
			display: flex;
			font-size: 1.4rem;
			gap: 1.3rem;
		}
		img {
			width: 4.8rem;
		}
	}
}