@use '0_utility/mixins/media-query';

.parallax-image {
	background: {
		position: center;
		repeat: no-repeat;
		size: cover;
	}
	height: 30rem;
	@include media-query.up(lg) {
		height: 56.8rem;
		@supports (background-attachment: fixed) {
			background-attachment: fixed;
		}
	}
	&.tall {
		@include media-query.up(lg) {
			height: 88.5rem;
		}
	}
}