@use '0_utility/variables/spacing';

.contact-form {
	&__description {
		height: 130px;
		width: 100%;
		resize: none;
	}
}
.row.justify-content-between.align-items-center {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}