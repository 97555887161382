@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.logo {
	&-marquee {
		&-header {
			margin-bottom: spacing.$default * 5;
			@include media-query.up(md) {
				margin-bottom: spacing.$lg;
			}
		}
	}
	&-card {
		align-items: center;
		border: 1px solid colors.$grayLight2;
		border-radius: .4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		width: 100%;
	}
}
