// Main Colors
$white: #fff;
$lightWhite: #FFFDFD;
$grayLight: #f8f8f8;
$grayLight2: #ebebeb;
$lightGray: #D5D5D5;
$navGray: #c4c4c4;
$gray: #808080;
$grayDark2: #3e3e3e;
$grayDark: #151515;
$black: #000;

$blueLight: #dbfdff;
$blueLight2: #d4ffff;
$brown: #99733a;
$gold: #99733a;
$green: #00e0ad;
$greenDark: #003428;
$greenGrey: #3AB48F;
$greenLight: #00e2ad;
$orange: #ffb800;
$pink: #f963bd;
$pinkLight: #ffeef9;
$red: #da2727;
$seafoam: #d3fff6;
$teal: #0cecf7;

// Default Colors
$text: $white;
$border: $black;
$error: $red;

// Button Colors
// $buttons: (
// 	primary: (
// 		background: $green,
// 		border: $green,
// 		text: $black
// 	),
// 	secondary: (
// 		background: $black,
// 		border: $grayLight,
// 		text: $grayLight
// 	)
// );

// Background Colors
// Used with a mixin to create class names for background colors.
// e.g. `.bg-gold` Remove if not needed
$backgroundColors: (
	'black': $black,
	'blue-light-2': $blueLight2,
	'blue-light': $blueLight,
	'brown': $brown,
	'gold': $gold,
	'gray-dark': $grayDark,
	'gray-light': $grayLight,
	'gray': $gray,
	'green-dark': $greenDark,
	'green': $green,
	'pink': $pink,
	'pink-light': $pinkLight,
	'seafoam': $seafoam,
	'teal': $teal,
	'white': $white,
);

// Text colors
// Used with a mixin to create class names for text colors.
$textColors: (
	'black': $black,
	'default': $white,
	'gold': $gold,
	'gray': $gray,
	'gray-dark': $grayDark,
	'gray-light': $grayLight,
	'green': $green,
	'white': $white,
);

$brand-1: $pink;
$brand-1-dark: darken($brand-1, 20%);
$brand-2: $green;
$brand-2-light: $greenLight;
$brand-2-dark: darken($brand-2, 20%);