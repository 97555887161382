@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.pagination {
	display: flex;
	justify-content: center;
	gap: spacing.$xs;
	span {
		background-color: transparent;
		border: .1rem solid colors.$white;
		border-radius: 50%;
		height: .5rem;
		padding: 0;
		transition: background-color .6s;
		width: .5rem;
		@at-root .black#{&} {
			border-color: colors.$black;
		}
	}
	.active {
		background-color: colors.$white;
		@at-root .black#{&} {
			background-color: colors.$black;
		}
	}
	.glide__bullet--active {
		background-color: colors.$white;
		@at-root .black#{&} {
			background-color: colors.$black;
		}
	}
}