@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-family';
@use '0_utility/variables/font-weights';

.partner-banner {
	background: linear-gradient(0deg, colors.$black 40%, colors.$white 40%);
	&-container {
		isolation: isolate;
		position: relative;
		@supports (font-size: clamp(6rem, 15vw, 19rem)) {
			&::after {
				bottom: 0;
				color: rgba(0, 52, 40, 0.04);
				content: attr(data-shadow-text);
				font: {
					size: clamp(6rem, 16vw, 23rem);
					family: font-family.$sans;
					weight: font-weights.$bold;
				}
				left: 50%;
				position: absolute;
				text-align: center;
				transform: translate(-50%, 30%);
				width: 100%;
				z-index: -1;
			}
		}
	}
	&-content {
		padding: 0 3rem 8rem;
		@include media-query.up(md) {
			padding: 3rem 0;
		}
	}
	img {
		width: 100%;
	}
	p {
		margin-block: 2.5rem 4rem;
		@include media-query.up(xl) {
			padding-right: 4rem;
		}
	}
}
