@use '0_utility/mixins/text-stroke';
@use '0_utility/variables/colors';

.shadow-label {
	overflow: hidden;
	@supports (font-size: clamp(6rem, 15vw, 19rem)) {
		&::after {
			color: colors.$black;
			content: attr(data-shadow-label);
			display: block;
			font: {
				weight: 700;
				size: clamp(6rem, 15vw, 19rem);
			}
			line-height: 1;
			opacity: 0.03;
			transform: translateX(-8%);
			width: max-content;
		}
	}

	&--outline {
		&::after {
			opacity: 1;
			@include text-stroke.stroke($width: 0.5px);
		}

		&-gray {
			&::after {
				@include text-stroke.stroke($color: colors.$gray, $width: 0.5px);
			}
		}
	}
	&--right {
		&::after {
			text-align: right;
			transform: translateX(0);
			width: auto;
		}
	}
}
