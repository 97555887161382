@use '0_utility/mixins/media-query';
@use '0_utility/mixins/text-stroke';
@use '0_utility/variables/colors';

.about-us {
	overflow: hidden;
	padding-top: 4rem;
	@include media-query.up(md) {
		padding-top: 8rem;
		@media (max-height: 800px) {
			padding-top: 20rem;
		}
	}
	.title-large {
		margin-bottom: 3rem;
	}
	&-container {
		display: flex;
		flex-direction: column;
	}
	&-content {
		@include media-query.up(md) {
			align-self: flex-end;
			max-width: 40%;
		}
		p {
			color: colors.$gray;
			margin-bottom: 1.4rem;
		}
	}
	&-marquee {
		p {
			display: none;
			@include text-stroke.stroke(colors.$gray);
			@supports (font-size: clamp(6rem, 15vw, 19rem)) {
				display: block;
				font-size: clamp(6rem, 15vw, 19rem);
				line-height: 1;
				transform: translateX(-5%);
			}
		}
	}
}