// Font family
@use '../mixins/fonts';
@use '../variables/file-path';

@include fonts.font-face(
	$name: "Proxima Nova",
	$path: "#{file-path.$fonts}proxima-nova/proxima-nova-thin",
	$weight: 100,
	$style: normal,
	$exts: ttf woff woff2
);
@include fonts.font-face(
	$name: "Proxima Nova",
	$path: "#{file-path.$fonts}proxima-nova/proxima-nova-regular",
	$weight: 400,
	$style: normal,
	$exts: ttf woff woff2
);
@include fonts.font-face(
	$name: "Proxima Nova",
	$path: "#{file-path.$fonts}proxima-nova/proxima-nova-bold",
	$weight: 700,
	$style: normal,
	$exts: ttf woff woff2
);
@include fonts.font-face(
	$name: "Proxima Nova",
	$path: "#{file-path.$fonts}proxima-nova/proxima-nova-black",
	$weight: 900,
	$style: normal,
	$exts: ttf woff woff2
);

$fallbackFonts: helvetica, arial, sans-serif;
$sans: "Proxima Nova", $fallbackFonts;
$default: $sans;
