@use '../0_utility/variables/max-width';
@use 'sass:meta';

.container {
	@if meta.global-variable-exists(multiple, $module: 'max-width') {
		@each $size, $max-width in max-width.$multiple {
			$infix: if($size == 'default', '', '-#{$size}');
			&#{$infix} {
				margin-inline: auto;
				max-width: $max-width;
				width: 100%;
			}
		}
	} @else {
		margin-inline: auto;
		max-width: max-width.$max-width;
		width: 100%;
	}
}
