@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.global-header {
	color: colors.$white;
	display: flex;
	flex-direction: column;
	mix-blend-mode: exclusion;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;

	@at-root {
		.theme--light & {
			color: colors.$black;
		}
	}

	&.menu-is-open {
		background: colors.$black;
		mix-blend-mode: unset;
	}

	.container {
		align-items: stretch;
		display: flex;
		justify-content: space-between;
		padding-block: spacing.$default;
		position: relative;
		width: 100%;
	}

	&__logo {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 35px;

		svg {
			fill: currentColor;
			width: 100%;
		}
	}

	&__nav-menu {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-between;

		&:not(.menu-is-open) {
			display: none;
		}
	}
}

.menu-button {
	align-items: center;
	background: none;
	border: 0;
	color: inherit;
	cursor: pointer;
	display: flex;
	height: 40px;
	justify-content: center;
	padding: 0;
	margin: 0;
	width: 40px;

	* {
		// pass all pointer events through to the button
		pointer-events: none !important;
	}

	&__icon {
		color: inherit;
		display: block;
		width: 100%;

		&::before,
		&::after {
			background-color: currentColor;
			border-radius: 10px;
			content: '';
			display: block;
			height: 3px;
			position: relative;
			transition: opacity 0.2s ease-in, transform 0.2s ease-in,
				width 0.2s ease-in;
		}

		&::before {
			margin-bottom: 5px;
			width: 40px;
		}

		&::after {
			margin-top: 5px;
			width: 30px;
		}

		.menu-is-open & {
			&::before,
			&::after {
				margin: 0 auto;
			}

			&::before {
				transform: translateY(50%) rotate(45deg);
				width: 30px;
			}

			&::after {
				transform: translateY(-50%) rotate(-45deg);
				width: 30px;
			}
		}
	}

	&:active,
	&:focus,
	&:hover {
		&:not(.menu-is-open) {
			.menu-button__icon {
				&::before,
				&::after {
					opacity: 0.7;
				}

				&::after {
					width: 40px;
				}
			}
		}
	}
}

.site-nav {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	width: 100%;
	@include media-query.down(md) {
		max-height: 80vh;
	}
	&__menu {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-around;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	&__item {
		text-align: center;
		opacity: 0;
		transform: translateY(100%);
		width: 100%;

		a {
			color: colors.$white;
			display: inline-block;
			font-size: 6rem;
			font-weight: 600;
			padding-inline: 4rem;
			transition: opacity 0.2s;

			&:active,
			&:focus,
			&:hover {
				opacity: 0.5;
			}
		}
	}
}
