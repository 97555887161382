@use '../0_utility/functions/infix';
@use '../0_utility/mixins/media-query';
@use '../0_utility/variables/breakpoints';
@use 'sass:map';

@each $bp in map.keys(breakpoints.$sizes) {
	$infix: infix.breakpoint($bp, breakpoints.$sizes);
	.hide#{$infix}-up {
		@include media-query.up($bp) {
			display: none !important;
		}
	}
	.hide#{$infix}-down {
		@include media-query.down($bp) {
			display: none !important;
		}
	}
}

.sr-only {
	height: 1px;
	left: -1000vw;
	overflow: hidden;
	position: absolute;
	width: 1px;
}
