@use "0_utility/mixins/media-query";
@use "0_utility/variables/colors";

.services {
	isolation: isolate;
	@include media-query.up(md) {
		overflow: hidden;
	}
	&-slides {
		display: grid;
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;
		.container {
			display: flex;
		}
		.slide {
			display: flex;
			grid-column: 1;
			grid-row: 1;
			pointer-events: none;
			position: relative;
			transition: opacity 0.6s;
			width: 100% !important;
			z-index: 1;
			&.active-slide {
				pointer-events: auto;
				z-index: 2;
			}
			&-row {
				position: relative;
				@include media-query.up(md) {
					display: flex;
					width: 100%;
				}
			}
			&-content {
				padding: 5.5rem 2rem 0;
				@include media-query.up(md) {
					margin-right: 5%;
					padding-inline: 0;
					width: 85%;
				}
				@include media-query.up(xl) {
					width: 65%;
				}
			}
			&-image {
				width: 100%;
				@include media-query.down(sm) {
					bottom: 0;
					left: 0;
					position: absolute;
					transform: translateY(50%);
					@media screen and (max-height: 600px) {
						transform: translateY(90%);
					}
				}
				@include media-query.up(md) {
					position: relative;
				}
				img {
					width: 100%;
					@include media-query.up(md) {
						left: 0;
						height: 100%;
						max-width: none;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: auto;
					}
				}
			}
		}
	}
	&-background {
		background-color: colors.$white;
		inset: 1rem 0 0 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
		@include media-query.up(md) {
			inset: 5vh 15vh 5vh 0;
			@media screen and (max-height: 750px) {
				inset-block: 4rem;
			}
		}
		@include media-query.up(lg) {
			right: 10vw;
		}
		@include media-query.up(xl) {
			right: 25vw;
		}
		.background {
			inset: 0;
			position: absolute;
		}
	}
	&-wrapper {
		display: flex;
		height: 100vh;
		position: relative;
		width: 100%;
		@include media-query.up(md) {
			background: none;
			padding-block: 15vh;
			@media screen and (max-height: 750px) {
				padding-block: 10rem;
			}
		}
	}
	&-carousel {
		display: flex;
		position: relative;
		width: 100%;
	}
	&-eyebrow {
		color: colors.$black;
		align-items: center;
		display: flex;
		font-size: 1.4rem;
		margin-bottom: 5px;
		.dash {
			background: currentColor;
			height: 0.2rem;
			margin-inline: 0.8rem;
			width: 3.2rem;
		}
	}
	&-quote {
		margin-block: 2rem;
		p {
			font-size: 2.2rem;
		}
		cite {
			align-items: center;
			display: flex;
			font-size: 1.4rem;
			margin-top: 2rem;
		}
		img {
			margin-right: 1.3rem;
			max-width: 50px;
		}
	}
}
