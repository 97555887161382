@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use 'sass:map';
@use 'sass:list';

$transition: 'opacity .3s ease-in-out, transform .3s ease-in-out';
$teamMembers: (
	// 1
	(
		top: (
			sm: '0'
		),
		left: (
			sm: '10%'
		)
	),
	// 2
	(
		top: (
			sm: '40%',
			md: '55%'
		),
		left: (
			sm: '0'
		)
	),
	// 3
	(
		top: (
			sm: '75%',
			md: '30%'
		),
		left: (
			sm: '10%',
			md: '30%'
		)
	),
	//4
	(
		top: (
			sm: '20%',
			md: '85%'
		),
		left: (
			sm: '40%',
			md: '30%'
		)
	),
	// 5
	(
		top: (
			sm: '55%',
			md: '8%'
		),
		left: (
			sm: '40%',
			md: '60%'
		)
	),
	// 6
	(
		top: (
			sm: '0',
			md: '55%'
		),
		right: (
			sm: '10%',
			md: '35%'
		)
	),
	// 7
	(
		top: (
			sm: '40%',
			md: '30%'
		),
		right: (
			sm: '0%',
			md: '10%'
		)
	),
	// 8
	(
		bottom: (
			sm: '0',
			xl: '0'
		),
		right: (
			sm: '10%',
			xl: '5%'
		)
	)
);

$cresentColors: (
	'pink': colors.$pink,
	'green': colors.$greenLight,
	'white': colors.$white,
	'orange': colors.$orange,
	'teal': colors.$teal
);
.meet-the-team {
	padding-block: 75vw 10rem;
	@include media-query.up(md) {
		padding-block: 25rem;
	}
	&-title {
		padding-block: 4rem;
		@include media-query.down(md) {
			max-width: 60rem;
		}
		.cta {
			margin-top: 2rem;
		}
	}
}
.team {
	width: 100%;
	position: relative;
	@include media-query.down(md) {
		height: 30rem;
	}
	&-member {
		isolation: isolate;
		position: absolute;
		@include media-query.up(md) {
			opacity: .5;
			transition: #{$transition};
			transition-delay: .2s;
		}
		@for $i from 1 through list.length($teamMembers) {
			$member: list.nth($teamMembers, $i);
			$key: map.keys($member);

			&-#{$i} {
				@each $positions in $key {
					$sizes: map.get($member, $positions);
					@each $size, $value in $sizes {
						@if $size == 'sm' {
							#{$positions}: #{$value};
						} @else {
							@include media-query.up(#{$size}) {
								#{$positions}: #{$value};
							}
						}
					}
				}
			}
		}
		&:hover {
			opacity: 1;
		}
		&-image {
			position: relative;
			width: 65px;
			@include media-query.up(md) {
				&::before {
					border-radius: 50%;
					bottom: 0;
					content: '';
					height: 110%;
					opacity: 0;
					position: absolute;
					right: 0;
					transform: scale(.7);
					transition: #{$transition};
					width: 110%;
					z-index: -1;
					@each $name, $color in $cresentColors {
						@at-root .cresent-#{$name}#{&} {
							background-color: $color;
						}
					}
					.team-member:hover & {
						opacity: 1;
						transform: scale(1);
						transition-delay: .2s
					}
				}
			}
			img {
				border-radius: 50%;
			}
			i {
				display: none;
				@include media-query.up(md) {
					display: block;
					bottom: 0;
					font-style: normal;
					opacity: 0;
					position: absolute;
					right: 0;
					transition: #{$transition};
					transition-delay: .2s;
					transform: translate(-50%, -50%);
					.team-member:hover & {
						opacity: 1;
						transform: translate(0, 0);
					}
				}
			}
		}
		&-name {
			display: none;
			@include media-query.up(md) {
				color: colors.$white;
				display: block;
				left: 50%;
				opacity: 0;
				position: absolute;
				text-align: center;
				transform: translate(-50%, 0);
				transition: #{$transition};
				top: 100%;
				width: max-content;
				.team-member:hover & {
					opacity: 1;
					transform: translate(-50%, 1rem);
					transition-delay: .2s;
				}
			}
		}
		&-title {
			color: colors.$gray;
			display: block;
		}
	}
}
