@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';
.careers--list {
	background-color:colors.$white;
    padding: spacing.$xxl spacing.$sm;
    h2{
        color:colors.$black;
        font-weight: 600;
        font-size: 6rem;
        line-height: 5.4rem;
        margin-top:spacing.$xl;
        @include media-query.down(sm) {
            font-size: 4.2rem;
            
        }
    }
    .job--lists{
        margin-bottom: spacing.$xl;
        ul{
            list-style: none;
            li:first-child{
                font-weight: 700;
                padding:spacing.$sm 0;
                &:hover {
                    background:none;
                }
            }
            li{
                @include media-query.down(sm) {
                    position: relative;
                } 
                
                a{
                    align-items: center;;
                    color: colors.$black;
                    display: flex;
                    justify-content: space-between;
                    gap: spacing.$default;
                    line-height: 1.2;
                    @include media-query.down(sm) {
                        max-width: 80%;
                    }
                    .icon{
                        margin: spacing.$sm 0;
                        @include media-query.down(sm) {
                            position: absolute;
                            right: 1rem;
                            bottom: 0.9rem;
                        }
                        svg{
                            border: 2px solid colors.$black;
                            border-radius: 50%;
                            padding: spacing.$xs;
                            
                            
                        }
                        @include media-query.down(sm) {
                            font-size: 2.4rem;
                        }
                    }
                    @include media-query.down(sm) {
                        font-size: 1.8rem;
                    }
                }
                border-bottom:1px solid colors.$lightGray;
                color: colors.$black;
                font-weight: 400;
                font-size: 2.4rem;
                line-height: 5.1rem;
                padding: spacing.$sm spacing.$sm;
                &:hover {
                    background: colors.$greenLight;
                    a{
                        .icon{
                            svg{
                                background: colors.$black;
                                fill:colors.$lightWhite;
                            }
                            
                        }
                    }
                }
                @include media-query.down(sm) {
                    font-size: 2rem;
                }
            }
            @include media-query.down(sm) {
                padding-left: 0;
            }   
        }

    }
    .col-sm-12{
        @include media-query.down(sm) {
            width: 100%;
        }   
    }
}
