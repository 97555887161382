@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.page-footer {
	background: colors.$green;
	bottom: 0;
	color: colors.$black;
	position: sticky;
	z-index: -1;

	&.footer--alt {
		background: colors.$black;
		color: colors.$white;
	}
}

.global-footer {
	font-size: 1.4rem;
	line-height: 1.125;
	padding: 0 spacing.$md;

	.container {
		align-items: center;
		border-top: 1px solid rgba(colors.$black, 0.40);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: spacing.$xl 0;

		@include media-query.up(md) {
			align-items: flex-start;
			flex-direction: row;
			justify-content: space-between;
		}

		.footer--alt & {
			border-top-color: rgba(colors.$white, 0.20);
		}
	}

	.copyright {
		font-size: 1.2rem;
		font-weight: 600;
		margin-bottom: spacing.$sm;

		@include media-query.up(md) {
			margin-bottom: 0;
		}
	}

	.footer-menu { //ul
		display: flex;
		flex-direction: row;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			list-style: none;
			margin: 0;
			padding: 0 spacing.$md;
		}

		a {
			color: inherit;
			display: block;
			padding: spacing.$xs;
			text-decoration: none !important;
		}

		.icon {
			display: block;

			svg {
				display: block;
				fill: currentColor;
				height: 15px;
				width: 15px;
			}
		}
	}
}
