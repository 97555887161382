@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';

.cta {
	align-items: center;
	color: colors.$black;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.6rem;
	font-weight: 600;
	justify-content: center;
	transition: opacity 0.2s ease-in;
	.icon {
		align-items: center;
		border: 1px solid colors.$black;
		border-radius: 100%;
		color: colors.$white;
		display: flex;
		height: 2.7rem;
		justify-content: center;
		margin-left: spacing.$default;
		transition: transform 0.2s ease-in;
		width: 2.7rem;
		svg {
			fill: colors.$black;
			width: 44%;
		}
	}
	&:hover {
		opacity: .8;
		.icon {
			transform: scale(1.1);
		}
	}
	&--light {
		color: colors.$white;
		.icon {
			border-color: colors.$green;
			color: colors.$green;
			svg {
				fill: colors.$green;
			}
		}
	}
	&.hide-md-up,
	&.hide-md-up > span{
		color: colors.$white;
	}
}
