@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';
@use '0_utility/variables/font-weights';

.article {
	@include media-query.up(md) {
		font-size: 2rem;
		h3 {
			font-size: 2.4rem;
		}
	}
	.row {
		& + .row {
			margin-top: 5rem;
		}
	}
	p {
		& + p {
			margin-top: 4rem;
		}
		a {
			font-weight: font-weights.$bold;
			text-decoration: underline;
		}
	}
	.quote-credit {
		font-size: 2.5rem;
		margin-top: .5rem;
	}
	&-hero {
		@include media-query.down(sm) {
			background-color: colors.$white;
			isolation: isolate;
			padding-bottom: 0;
			position: relative;
			&::before {
				background-color: colors.$black;
				content: '';
				inset: 0;
				position: absolute;
				z-index: -1;
				height: calc(100% - 50vw)
			}
		}
		a {
			align-items: center;
			color: colors.$green;
			display: flex;
			font-size: 1.2rem;
			gap: .4rem;
			margin-bottom: 1.8rem;
		}
		.icon {
			transform: rotate(180deg);
		}
		&-quote {
			img {
				width: 4.8rem;
			}
			cite {
				align-items: center;
				display: flex;
				font-weight: font-weights.$medium;
				gap: spacing.$md;
				margin-top: spacing.$xl;
			}
			span {
				color: colors.$gray;
				display: block;
				font-weight: font-weights.$regular;
			}
		}
	}
	&-list {
		list-style-type: circle;
		padding-left: 1.6rem;
		li {
			color: colors.$pink;
			& + li {
				margin-top: 4rem;
			}
		}
		p {
			color: colors.$black;
		}
	}
	&-image {
		margin-block: 4rem;
		figcaption {
			font-style: italic;
			margin-top: 1.5rem;
		}
	}
}
