@use '0_utility/mixins/media-query';

.lock-scroll {
	overflow: visible;

	body {
		overflow: hidden;
	}
}
.homepage {
	&-banner {
		position: relative;
		.hero {
			padding-bottom: 0;
			@include media-query.down(sm) {
				display: none;
			}
			min-height: 100vh;
			&-main-image {
				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}
		.hide {
			display: none;
		}
	}
	&-carousel {
		@include media-query.up(md) {
			align-items: center;
			display: flex;
			left: 0;
			top: 0;
			right: 0;
			min-height: 100vh;
			position: absolute;
			&-wrapper {
				width: 100%;
			}
			.animating & {
				pointer-events: none;
			}
			.glide__slides {
				margin-block: 0;
			}
			.glide__slide {
				opacity: 0;
				&--active {
					z-index: 1;
				}
			}
		}
		&-header {
			padding: 10rem 1.5rem 2rem;
			text-align: center;
			margin-block: 3rem;
			@include media-query.up(md) {
				margin-inline: auto;
				max-width: 45.4rem;
				opacity: 0;
				padding: 0;
			}
		}
	}
}