@use '0_utility/mixins/media-query';

.graph-circle {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 4.3rem;
	padding: 6rem 3rem;
	@include media-query.up(md) {
		flex-direction: row;
		gap: 3.5rem;
		padding: 5.5rem 5rem;
	}
	.graph {
		flex: 1;
	}
	p {
		flex: 1.5;
		font-size: 1.8rem;
		@include media-query.up(md) {
			font-size: 2rem;
		}
	}
}