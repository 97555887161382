@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';

.social-icons {
	align-items: center;
	border: .1rem solid colors.$black;
	display: flex;
	gap: 3.2rem;
	justify-content: space-around;
	padding-block: 1rem;
	margin-bottom: 5rem;
	@include media-query.up(md) {
		flex-direction: column;
		padding-block: 3.2rem;
		margin-bottom: 0;
		width: 9rem;
	}
	a {
		width: 2rem;
	}
	.facebook {
		width: 1.1rem;
	}
}