@use '0_utility/mixins/media-query';
@use '0_utility/variables/colors';
@use '0_utility/variables/font-sizes';
@use '0_utility/variables/spacing';

.card {
	background: colors.$grayDark2;
	padding: 2px;
	position: relative;
	figure {
		@include media-query.up(md) {
			overflow: hidden;
			border-radius: 10px;
		}
	}
	> img {
		width: 100%;
	}

	&__content {
		background: colors.$white;
		border-top: 1px solid colors.$black;
		display: block;
		color: colors.$black;
		overflow: hidden;
		padding: spacing.$xl;
		position: relative;
		&:hover {
			.cta {
				opacity: .8;
				.icon {
					transform: scale(1.1);
				}
			}
		}
	}

	&__eyebrow {
		color: colors.$brown;
		font-size: font-sizes.$small;
		margin-bottom: spacing.$lg;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			right: calc(100% + 5px);
			background: colors.$brown;
			height: 2px;
			width: 100px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__heading {
		margin-bottom: spacing.$default;
	}

	&__description {
		color: colors.$gray;
		margin-bottom: spacing.$lg;
	}

	&--hero {

		@include media-query.up(md) {
			border-radius: 10px;
			overflow: hidden;
			padding: 10px;
		}

		> img {
			@include media-query.up(md) {
				border-radius: 10px;
			}
		}

		.card { // inner namespace

			&__content {
				display: block;
				@include media-query.up(md) {
					background-color: rgba(colors.$white, .8);
					border-bottom-left-radius: 10px;
					border-top: 0;
					bottom: 10px;
					left: 10px;
					position: absolute;
					width: 50%;
				}
				@include media-query.up(xl) {
					width: 70%;
				}
				img {
					margin-bottom: 0.5rem;
					max-width: 15rem;
				}
			}

			&__content-inner {

				@include media-query.up(md) {

				}

			}
		}
	}
}
