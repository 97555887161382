@charset "UTF-8";
@font-face {
  font-display: block;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 100;
  src: url("~../fonts/proxima-nova/proxima-nova-thin.ttf") format("truetype"), url("~../fonts/proxima-nova/proxima-nova-thin.woff") format("woff"), url("~../fonts/proxima-nova/proxima-nova-thin.woff2") format("woff2");
}
@font-face {
  font-display: block;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  src: url("~../fonts/proxima-nova/proxima-nova-regular.ttf") format("truetype"), url("~../fonts/proxima-nova/proxima-nova-regular.woff") format("woff"), url("~../fonts/proxima-nova/proxima-nova-regular.woff2") format("woff2");
}
@font-face {
  font-display: block;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  src: url("~../fonts/proxima-nova/proxima-nova-bold.ttf") format("truetype"), url("~../fonts/proxima-nova/proxima-nova-bold.woff") format("woff"), url("~../fonts/proxima-nova/proxima-nova-bold.woff2") format("woff2");
}
@font-face {
  font-display: block;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 900;
  src: url("~../fonts/proxima-nova/proxima-nova-black.ttf") format("truetype"), url("~../fonts/proxima-nova/proxima-nova-black.woff") format("woff"), url("~../fonts/proxima-nova/proxima-nova-black.woff2") format("woff2");
}
*, *:before,
* *:after {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  color: #fff;
  font-family: "Proxima Nova", helvetica, arial, sans-serif;
  font-size: 62.5%;
  line-height: 1.4;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #000;
  font-size: 1.8rem;
  margin: 0;
}

/*
 * Remove text-shadow in selection highlight:
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * Remove default figure styles.
 */
figure {
  margin: 0;
}

/*
 * Remove default blockquote styles.
 */
blockquote {
  margin: 0;
}

cite {
  font-style: normal;
}

/**
 * Remove the gray background color from active links in IE 10.
 * Remove default color
 */
a {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active,
a:hover,
a:focus {
  outline: 0;
}

a:focus-visible {
  outline: 0.3rem solid #da2727;
  outline-offset: 5px;
}

button:focus-visible {
  outline: 0.3rem solid #da2727;
  outline-offset: 5px;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
strong {
  font-weight: 700;
}

/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border-style: none;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

[tabindex="-1"]:focus {
  outline: none;
}

.icon {
  display: inline-block;
  width: 1em;
}
.icon svg {
  display: block;
  fill: currentColor;
  max-width: 100%;
  width: 100%;
}

.section-block-sm {
  padding-block: 2rem;
}

.section-block {
  padding-block: 4rem;
}
@media (min-width: 768px) {
  .section-block {
    padding-block: 6rem;
  }
}

.section-block-lg {
  padding-block: 6rem;
}
@media (min-width: 768px) {
  .section-block-lg {
    padding-block: 8rem;
  }
}

.section-block-xl {
  padding-block: 8rem;
}
@media (min-width: 768px) {
  .section-block-xl {
    padding-block: 12rem;
  }
}

@custom-selector :--heading h1,
h2,
h3,
h4,
h5,
h6;
:--heading,
p {
  margin-block: 0;
}

:--heading {
  line-height: 1.1;
}

.title-small {
  font-size: 1.8rem;
}

.title-medium {
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .title-medium {
    font-size: 2.8rem;
  }
}

.title-large {
  font-size: 4rem;
}
@media (min-width: 768px) {
  .title-large {
    font-size: 6rem;
  }
}

h1 {
  font-size: 4rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  h1 {
    font-size: 6rem;
  }
}

h2 {
  font-size: 6rem;
}

h3 {
  font-size: 2.2rem;
  font-weight: 600;
}

:--heading + p, p + p {
  margin-top: 1.5rem;
}

.text-black {
  color: #000;
}

.text-default {
  color: #fff;
}

.text-gold {
  color: #99733a;
}

.text-gray {
  color: #808080;
}

.text-gray-dark {
  color: #151515;
}

.text-gray-light {
  color: #f8f8f8;
}

.text-green {
  color: #00e0ad;
}

.text-white {
  color: #fff;
}

.text-stroke {
  color: #fff;
}
@supports (text-stroke: 1px white) or (-webkit-text-stroke: 1px white) {
  .text-stroke {
    color: transparent;
    -webkit-text-stroke: 0.5px #fff;
    text-stroke: 0.5px #fff;
  }
}
.text-stroke--green {
  color: #00e0ad;
}
@supports (text-stroke: 1px white) or (-webkit-text-stroke: 1px white) {
  .text-stroke--green {
    color: transparent;
    -webkit-text-stroke: 0.5px #00e0ad;
    text-stroke: 0.5px #00e0ad;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

form {
  border: 0;
  display: block;
  margin: 0;
  padding: 0;
}
form p {
  font-size: inherit;
  line-height: inherit;
}

input:not([type=button], [type=submit], [type=checkbox], [type=radio]) {
  background: none;
  border: 0;
  border-bottom: 1px solid #f8f8f8;
  color: inherit;
  font-size: 0.7em;
  font-family: inherit;
  margin: 0;
  outline: none;
  padding: 0 0.5rem;
}
input:-webkit-autofill, input:-webkit-autofill:-webkit-autofill:hover, input:-webkit-autofill:-webkit-autofill:focus, input:-webkit-autofill:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

textarea {
  background: none;
  border: 1px solid #f8f8f8;
  color: inherit;
  font-size: 0.7em;
  font-family: "Proxima Nova", helvetica, arial, sans-serif;
  margin: 0;
  outline: none;
  padding: 1.5rem;
}

label {
  color: transparent;
  display: block;
  height: 0;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  width: 0;
}

.contact-form {
  margin-bottom: 4rem;
}

.inline-form {
  font-size: 3rem;
  line-height: 2;
}
.inline-form .form-field {
  display: inline-block;
}
.inline-form .form-field--block {
  display: block;
}
.inline-form input:not([type=button], [type=submit], [type=checkbox], [type=radio]) {
  width: 25rem;
}
.inline-form textarea {
  vertical-align: text-top;
}
.inline-form .button {
  margin-inline: auto;
}
.inline-form .submit-button__icon {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  transform: rotate(-45deg);
}

.form-field-group + .form-field-group {
  margin-top: 3rem;
}

.form-field {
  display: block;
  max-width: 100%;
  position: relative;
}
.form-field.has-error .form-control:not([type=button], [type=submit], [type=checkbox], [type=radio]) {
  border-color: #da2727;
}

.form-control {
  max-width: 100%;
}

.field-hint {
  color: #f8f8f8;
  font-size: 1.2rem;
  left: 0;
  position: absolute;
  top: 100%;
  text-align: center;
  transform: translateY(-50%);
  width: 100%;
}
.has-error .field-hint {
  color: #da2727;
}

.wrapper {
  padding-inline: 1.5rem;
}
@media (min-width: 992px) {
  .wrapper {
    padding-inline: 2rem;
  }
}

.container-xs {
  margin-inline: auto;
  max-width: 37rem;
  width: 100%;
}
.container-sm {
  margin-inline: auto;
  max-width: 50rem;
  width: 100%;
}
.container-md {
  margin-inline: auto;
  max-width: 89.4rem;
  width: 100%;
}
.container-standard {
  margin-inline: auto;
  max-width: 100rem;
  width: 100%;
}
.container {
  margin-inline: auto;
  max-width: 132rem;
  width: 100%;
}
.container-xl {
  margin-inline: auto;
  max-width: 150rem;
  width: 100%;
}

.row {
  display: flex;
  margin-inline: -0.75rem;
  row-gap: 1.5rem;
}
.row:not(.no-wrap) {
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .row {
    margin-inline: -1rem;
    row-gap: 2rem;
  }
}

.row-no-gutters {
  display: flex;
}
.row-no-gutters:not(.no-wrap) {
  flex-wrap: wrap;
}

.column {
  width: 100%;
}
.row > .column {
  padding-inline: 0.75rem;
}
@media (min-width: 992px) {
  .row > .column {
    padding-inline: 1rem;
  }
}
.flex-children > .column {
  display: flex;
}
.column-box {
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-left-auto {
  margin-left: auto;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-left-sm-auto {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-left-md-auto {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-left-lg-auto {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-left-xl-auto {
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  .col-max {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-max-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-max-1 {
    flex: 0 0 8.3333333333%;
  }

  .col-max-2 {
    flex: 0 0 16.6666666667%;
  }

  .col-max-3 {
    flex: 0 0 25%;
  }

  .col-max-4 {
    flex: 0 0 33.3333333333%;
  }

  .col-max-5 {
    flex: 0 0 41.6666666667%;
  }

  .col-max-6 {
    flex: 0 0 50%;
  }

  .col-max-7 {
    flex: 0 0 58.3333333333%;
  }

  .col-max-8 {
    flex: 0 0 66.6666666667%;
  }

  .col-max-9 {
    flex: 0 0 75%;
  }

  .col-max-10 {
    flex: 0 0 83.3333333333%;
  }

  .col-max-11 {
    flex: 0 0 91.6666666667%;
  }

  .col-max-12 {
    flex: 0 0 100%;
  }

  .offset-max-0 {
    margin-left: 0;
  }

  .offset-max-1 {
    margin-left: 8.3333333333%;
  }

  .offset-max-2 {
    margin-left: 16.6666666667%;
  }

  .offset-max-3 {
    margin-left: 25%;
  }

  .offset-max-4 {
    margin-left: 33.3333333333%;
  }

  .offset-max-5 {
    margin-left: 41.6666666667%;
  }

  .offset-max-6 {
    margin-left: 50%;
  }

  .offset-max-7 {
    margin-left: 58.3333333333%;
  }

  .offset-max-8 {
    margin-left: 66.6666666667%;
  }

  .offset-max-9 {
    margin-left: 75%;
  }

  .offset-max-10 {
    margin-left: 83.3333333333%;
  }

  .offset-max-11 {
    margin-left: 91.6666666667%;
  }

  .order-max-first {
    order: -1;
  }

  .order-max-last {
    order: 13;
  }

  .order-max-0 {
    order: 0;
  }

  .order-max-1 {
    order: 1;
  }

  .order-max-2 {
    order: 2;
  }

  .order-max-3 {
    order: 3;
  }

  .order-max-4 {
    order: 4;
  }

  .order-max-5 {
    order: 5;
  }

  .order-max-6 {
    order: 6;
  }

  .order-max-7 {
    order: 7;
  }

  .order-max-8 {
    order: 8;
  }

  .order-max-9 {
    order: 9;
  }

  .order-max-10 {
    order: 10;
  }

  .order-max-11 {
    order: 11;
  }

  .order-max-12 {
    order: 12;
  }

  .offset-left-max-auto {
    margin-left: auto;
  }
}
.hide-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hide-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hide-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hide-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hide-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hide-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hide-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hide-xl-up {
    display: none !important;
  }
}

@media (max-width: 1399px) {
  .hide-xl-down {
    display: none !important;
  }
}

@media (min-width: 1400px) {
  .hide-max-up {
    display: none !important;
  }
}

.hide-max-down {
  display: none !important;
}

.sr-only {
  height: 1px;
  left: -1000vw;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.flex {
  display: flex;
}

.flex-first {
  order: -1;
}

.flex-last {
  order: 1;
}

.flex-unordered {
  order: 0;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-first {
    order: -1;
  }

  .flex-sm-last {
    order: 1;
  }

  .flex-sm-unordered {
    order: 0;
  }

  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-content-sm-start {
    align-content: flex-start;
  }

  .align-content-sm-end {
    align-content: flex-end;
  }

  .align-content-sm-center {
    align-content: center;
  }

  .align-content-sm-between {
    align-content: space-between;
  }

  .align-content-sm-around {
    align-content: space-around;
  }

  .align-content-sm-stretch {
    align-content: stretch;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }
}
@media (min-width: 768px) {
  .flex-md-first {
    order: -1;
  }

  .flex-md-last {
    order: 1;
  }

  .flex-md-unordered {
    order: 0;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-content-md-start {
    align-content: flex-start;
  }

  .align-content-md-end {
    align-content: flex-end;
  }

  .align-content-md-center {
    align-content: center;
  }

  .align-content-md-between {
    align-content: space-between;
  }

  .align-content-md-around {
    align-content: space-around;
  }

  .align-content-md-stretch {
    align-content: stretch;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }
}
@media (min-width: 992px) {
  .flex-lg-first {
    order: -1;
  }

  .flex-lg-last {
    order: 1;
  }

  .flex-lg-unordered {
    order: 0;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-content-lg-start {
    align-content: flex-start;
  }

  .align-content-lg-end {
    align-content: flex-end;
  }

  .align-content-lg-center {
    align-content: center;
  }

  .align-content-lg-between {
    align-content: space-between;
  }

  .align-content-lg-around {
    align-content: space-around;
  }

  .align-content-lg-stretch {
    align-content: stretch;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1200px) {
  .flex-xl-first {
    order: -1;
  }

  .flex-xl-last {
    order: 1;
  }

  .flex-xl-unordered {
    order: 0;
  }

  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-items-xl-baseline {
    align-items: baseline;
  }

  .align-items-xl-stretch {
    align-items: stretch;
  }

  .align-content-xl-start {
    align-content: flex-start;
  }

  .align-content-xl-end {
    align-content: flex-end;
  }

  .align-content-xl-center {
    align-content: center;
  }

  .align-content-xl-between {
    align-content: space-between;
  }

  .align-content-xl-around {
    align-content: space-around;
  }

  .align-content-xl-stretch {
    align-content: stretch;
  }

  .align-self-xl-auto {
    align-self: auto;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .align-self-xl-baseline {
    align-self: baseline;
  }

  .align-self-xl-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1400px) {
  .flex-max-first {
    order: -1;
  }

  .flex-max-last {
    order: 1;
  }

  .flex-max-unordered {
    order: 0;
  }

  .flex-max-row {
    flex-direction: row;
  }

  .flex-max-column {
    flex-direction: column;
  }

  .flex-max-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-max-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-max-wrap {
    flex-wrap: wrap;
  }

  .flex-max-nowrap {
    flex-wrap: nowrap;
  }

  .flex-max-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-max-start {
    justify-content: flex-start;
  }

  .justify-content-max-end {
    justify-content: flex-end;
  }

  .justify-content-max-center {
    justify-content: center;
  }

  .justify-content-max-between {
    justify-content: space-between;
  }

  .justify-content-max-around {
    justify-content: space-around;
  }

  .align-items-max-start {
    align-items: flex-start;
  }

  .align-items-max-end {
    align-items: flex-end;
  }

  .align-items-max-center {
    align-items: center;
  }

  .align-items-max-baseline {
    align-items: baseline;
  }

  .align-items-max-stretch {
    align-items: stretch;
  }

  .align-content-max-start {
    align-content: flex-start;
  }

  .align-content-max-end {
    align-content: flex-end;
  }

  .align-content-max-center {
    align-content: center;
  }

  .align-content-max-between {
    align-content: space-between;
  }

  .align-content-max-around {
    align-content: space-around;
  }

  .align-content-max-stretch {
    align-content: stretch;
  }

  .align-self-max-auto {
    align-self: auto;
  }

  .align-self-max-start {
    align-self: flex-start;
  }

  .align-self-max-end {
    align-self: flex-end;
  }

  .align-self-max-center {
    align-self: center;
  }

  .align-self-max-baseline {
    align-self: baseline;
  }

  .align-self-max-stretch {
    align-self: stretch;
  }
}
.bg-black {
  background-color: #000;
}

.bg-blue-light-2 {
  background-color: #d4ffff;
}

.bg-blue-light {
  background-color: #dbfdff;
}

.bg-brown {
  background-color: #99733a;
}

.bg-gold {
  background-color: #99733a;
}

.bg-gray-dark {
  background-color: #151515;
}

.bg-gray-light {
  background-color: #f8f8f8;
}

.bg-gray {
  background-color: #808080;
}

.bg-green-dark {
  background-color: #003428;
}

.bg-green {
  background-color: #00e0ad;
}

.bg-pink {
  background-color: #f963bd;
}

.bg-pink-light {
  background-color: #ffeef9;
}

.bg-seafoam {
  background-color: #d3fff6;
}

.bg-teal {
  background-color: #0cecf7;
}

.bg-white {
  background-color: #fff;
}

.bg-black-to-white {
  background: linear-gradient(to bottom, #000 50%, #fff 50%);
}

.button {
  align-items: center;
  background: #00e0ad;
  border: 1px solid currentColor;
  border-radius: 100%;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: 600;
  height: 20rem;
  justify-content: center;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  width: 20rem;
}
.button[disabled] {
  background: #151515;
  border-color: #151515;
  color: #808080;
  cursor: not-allowed !important;
}
.button:active:not([disabled]), .button:focus:not([disabled]), .button:hover:not([disabled]) {
  background: #fff;
  color: #000;
}
.button .icon {
  display: block;
  height: 1em;
  width: 1em;
}
.button .icon svg {
  fill: currentColor;
  width: 100%;
}

.cta {
  align-items: center;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.6rem;
  font-weight: 600;
  justify-content: center;
  transition: opacity 0.2s ease-in;
}
.cta .icon {
  align-items: center;
  border: 1px solid #000;
  border-radius: 100%;
  color: #fff;
  display: flex;
  height: 2.7rem;
  justify-content: center;
  margin-left: 1rem;
  transition: transform 0.2s ease-in;
  width: 2.7rem;
}
.cta .icon svg {
  fill: #000;
  width: 44%;
}
.cta:hover {
  opacity: 0.8;
}
.cta:hover .icon {
  transform: scale(1.1);
}
.cta--light {
  color: #fff;
}
.cta--light .icon {
  border-color: #00e0ad;
  color: #00e0ad;
}
.cta--light .icon svg {
  fill: #00e0ad;
}
.cta.hide-md-up, .cta.hide-md-up > span {
  color: #fff;
}

.custom-cursor {
  cursor: none !important;
}

.cursor {
  display: none;
}
@media (min-width: 768px) {
  .cursor {
    align-items: center;
    background: #000;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 129px;
    justify-content: center;
    left: 0;
    line-height: 1.125;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: opacity 0.15s ease-in;
    width: 129px;
  }
  .cursor,
.cursor * {
    cursor: none !important;
    pointer-events: none !important;
  }
  .cursor--active {
    opacity: 1;
  }
  .cursor__icon {
    font-size: 3.2rem;
  }
  .cursor__primary-text {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .cursor__subtext {
    color: #f8f8f8;
    font-size: 1.6rem;
    font-weight: 400;
  }
  .cursor-contact {
    background: #fff;
    color: #000;
  }
  .cursor-contact .icon {
    width: 1.8rem;
    transform: rotate(-45deg);
  }
  .cursor-cta {
    background: #fff;
    color: #000;
  }
  .cursor-cta .icon {
    width: 1.8rem;
  }
  .cursor-nav {
    height: 120px;
    width: 120px;
  }
  .cursor-nav--work {
    background: rgba(0, 224, 173, 0.8);
  }
  .cursor-nav--services {
    background: rgba(153, 115, 58, 0.8);
  }
  .cursor-nav--careers {
    background: rgba(255, 255, 255, 0.8);
  }
  .cursor-nav--contact {
    background: rgba(249, 99, 189, 0.8);
  }
  .cursor-carousel {
    background: #00e0ad;
    color: #000;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 1.4rem;
  }
  .cursor-carousel .arrow {
    font-size: 0;
    width: 1rem;
  }
  .cursor-carousel .next {
    transform: rotate(180deg);
  }
  .cursor-carousel svg {
    width: 100%;
  }
  .cursor-services {
    color: #000;
  }
  .cursor-services .icon {
    width: 1.8rem;
    transform: rotate(-45deg);
  }
  .cursor-services-strategy, .cursor-services-data {
    background-color: #00e0ad;
  }
  .cursor-services-creative {
    background-color: #fff;
  }
  .cursor-services-optimization, .cursor-services-automation, .cursor-services-technology {
    background-color: #fff;
  }
}

.marquee {
  overflow: hidden;
}
.marquee__content {
  max-width: min-content;
  position: relative;
}

.shadow-label {
  overflow: hidden;
}
@supports (font-size: clamp(6rem, 15vw, 19rem)) {
  .shadow-label::after {
    color: #000;
    content: attr(data-shadow-label);
    display: block;
    font-weight: 700;
    font-size: clamp(6rem, 15vw, 19rem);
    line-height: 1;
    opacity: 0.03;
    transform: translateX(-8%);
    width: max-content;
  }
}
.shadow-label--outline::after {
  opacity: 1;
  color: #fff;
}
@supports (text-stroke: 1px white) or (-webkit-text-stroke: 1px white) {
  .shadow-label--outline::after {
    color: transparent;
    -webkit-text-stroke: 0.5px #fff;
    text-stroke: 0.5px #fff;
  }
}
.shadow-label--outline-gray::after {
  color: #808080;
}
@supports (text-stroke: 1px white) or (-webkit-text-stroke: 1px white) {
  .shadow-label--outline-gray::after {
    color: transparent;
    -webkit-text-stroke: 0.5px #808080;
    text-stroke: 0.5px #808080;
  }
}
.shadow-label--right::after {
  text-align: right;
  transform: translateX(0);
  width: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.pagination span {
  background-color: transparent;
  border: 0.1rem solid #fff;
  border-radius: 50%;
  height: 0.5rem;
  padding: 0;
  transition: background-color 0.6s;
  width: 0.5rem;
}
.black.pagination span {
  border-color: #000;
}

.pagination .active {
  background-color: #fff;
}
.black.pagination .active {
  background-color: #000;
}

.pagination .glide__bullet--active {
  background-color: #fff;
}
.black.pagination .glide__bullet--active {
  background-color: #000;
}

@keyframes walk {
  0% {
    transform: scaleX(1);
  }
  49.9999999% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(-1);
  }
}
.i-am-lost {
  background: none;
  border: none;
  color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  height: 10px;
  width: 10px;
  font-size: 0;
}

.cursor.guide {
  height: 100px;
  width: 100px;
  background: none;
  border: 0;
}
.cursor.guide img {
  animation: walk 1s linear infinite;
  display: block;
  max-width: 100%;
}

.lock-scroll {
  overflow: visible;
}
.lock-scroll body {
  overflow: hidden;
}

.homepage-banner {
  position: relative;
}
.homepage-banner .hero {
  padding-bottom: 0;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .homepage-banner .hero {
    display: none;
  }
}
.homepage-banner .hero-main-image img {
  height: 100%;
  object-fit: cover;
}
.homepage-banner .hide {
  display: none;
}
@media (min-width: 768px) {
  .homepage-carousel {
    align-items: center;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    min-height: 100vh;
    position: absolute;
  }
  .homepage-carousel-wrapper {
    width: 100%;
  }
  .animating .homepage-carousel {
    pointer-events: none;
  }
  .homepage-carousel .glide__slides {
    margin-block: 0;
  }
  .homepage-carousel .glide__slide {
    opacity: 0;
  }
  .homepage-carousel .glide__slide--active {
    z-index: 1;
  }
}
.homepage-carousel-header {
  padding: 10rem 1.5rem 2rem;
  text-align: center;
  margin-block: 3rem;
}
@media (min-width: 768px) {
  .homepage-carousel-header {
    margin-inline: auto;
    max-width: 45.4rem;
    opacity: 0;
    padding: 0;
  }
}

.social-icons {
  align-items: center;
  border: 0.1rem solid #000;
  display: flex;
  gap: 3.2rem;
  justify-content: space-around;
  padding-block: 1rem;
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .social-icons {
    flex-direction: column;
    padding-block: 3.2rem;
    margin-bottom: 0;
    width: 9rem;
  }
}
.social-icons a {
  width: 2rem;
}
.social-icons .facebook {
  width: 1.1rem;
}

.about-us {
  overflow: hidden;
  padding-top: 4rem;
}
@media (min-width: 768px) {
  .about-us {
    padding-top: 8rem;
  }
}
@media (min-width: 768px) and (max-height: 800px) {
  .about-us {
    padding-top: 20rem;
  }
}
.about-us .title-large {
  margin-bottom: 3rem;
}
.about-us-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .about-us-content {
    align-self: flex-end;
    max-width: 40%;
  }
}
.about-us-content p {
  color: #808080;
  margin-bottom: 1.4rem;
}
.about-us-marquee p {
  display: none;
  color: #808080;
}
@supports (text-stroke: 1px white) or (-webkit-text-stroke: 1px white) {
  .about-us-marquee p {
    color: transparent;
    -webkit-text-stroke: 1px #808080;
    text-stroke: 1px #808080;
  }
}
@supports (font-size: clamp(6rem, 15vw, 19rem)) {
  .about-us-marquee p {
    display: block;
    font-size: clamp(6rem, 15vw, 19rem);
    line-height: 1;
    transform: translateX(-5%);
  }
}

@media (min-width: 768px) {
  .article {
    font-size: 2rem;
  }
  .article h3 {
    font-size: 2.4rem;
  }
}
.article .row + .row {
  margin-top: 5rem;
}
.article p + p {
  margin-top: 4rem;
}
.article p a {
  font-weight: 700;
  text-decoration: underline;
}
.article .quote-credit {
  font-size: 2.5rem;
  margin-top: 0.5rem;
}
@media (max-width: 767px) {
  .article-hero {
    background-color: #fff;
    isolation: isolate;
    padding-bottom: 0;
    position: relative;
  }
  .article-hero::before {
    background-color: #000;
    content: "";
    inset: 0;
    position: absolute;
    z-index: -1;
    height: calc(100% - 50vw);
  }
}
.article-hero a {
  align-items: center;
  color: #00e0ad;
  display: flex;
  font-size: 1.2rem;
  gap: 0.4rem;
  margin-bottom: 1.8rem;
}
.article-hero .icon {
  transform: rotate(180deg);
}
.article-hero-quote img {
  width: 4.8rem;
}
.article-hero-quote cite {
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 2rem;
  margin-top: 4rem;
}
.article-hero-quote span {
  color: #808080;
  display: block;
  font-weight: 400;
}
.article-list {
  list-style-type: circle;
  padding-left: 1.6rem;
}
.article-list li {
  color: #f963bd;
}
.article-list li + li {
  margin-top: 4rem;
}
.article-list p {
  color: #000;
}
.article-image {
  margin-block: 4rem;
}
.article-image figcaption {
  font-style: italic;
  margin-top: 1.5rem;
}

.graph-circle {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4.3rem;
  padding: 6rem 3rem;
}
@media (min-width: 768px) {
  .graph-circle {
    flex-direction: row;
    gap: 3.5rem;
    padding: 5.5rem 5rem;
  }
}
.graph-circle .graph {
  flex: 1;
}
.graph-circle p {
  flex: 1.5;
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .graph-circle p {
    font-size: 2rem;
  }
}

.parallax-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30rem;
}
@media (min-width: 992px) {
  .parallax-image {
    height: 56.8rem;
  }
  @supports (background-attachment: fixed) {
    .parallax-image {
      background-attachment: fixed;
    }
  }
}
@media (min-width: 992px) {
  .parallax-image.tall {
    height: 88.5rem;
  }
}

.bottom-ctas {
  text-align: center;
}
.bottom-ctas-links {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 7.5rem;
}
@media (min-width: 768px) {
  .bottom-ctas-links {
    flex-direction: row;
  }
}
.bottom-ctas-links a {
  flex: 1;
  position: relative;
}
.bottom-ctas-links img {
  width: 100%;
}
.bottom-ctas-links .cta-info {
  align-items: center;
  display: flex;
  font-weight: 600;
  flex-direction: column;
  height: 100%;
  inset: 0;
  margin-inline: auto;
  max-width: 48.6rem;
  padding: max(5vw, 10rem) 2rem 2rem;
  position: absolute;
}
@media (min-width: 768px) {
  .bottom-ctas-links .cta-info {
    padding-top: 35%;
  }
}
.bottom-ctas-links .cta-info .cta {
  margin-top: auto;
}
.bottom-ctas-links .cta-type {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  gap: 0.5rem;
}
.bottom-ctas-links .cta-title {
  font-size: 3.2rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .bottom-ctas-links .cta-title {
    font-size: 4rem;
  }
}
.bottom-ctas-links .divider {
  background-color: #fff;
  height: 0.1rem;
  width: 3.5rem;
}

.card {
  background: #3e3e3e;
  padding: 2px;
  position: relative;
}
@media (min-width: 768px) {
  .card figure {
    overflow: hidden;
    border-radius: 10px;
  }
}
.card > img {
  width: 100%;
}
.card__content {
  background: #fff;
  border-top: 1px solid #000;
  display: block;
  color: #000;
  overflow: hidden;
  padding: 4rem;
  position: relative;
}
.card__content:hover .cta {
  opacity: 0.8;
}
.card__content:hover .cta .icon {
  transform: scale(1.1);
}
.card__eyebrow {
  color: #99733a;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  position: relative;
}
.card__eyebrow::before {
  content: "";
  display: block;
  position: absolute;
  right: calc(100% + 5px);
  background: #99733a;
  height: 2px;
  width: 100px;
  top: 50%;
  transform: translateY(-50%);
}
.card__heading {
  margin-bottom: 1rem;
}
.card__description {
  color: #808080;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .card--hero {
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
  }
}
@media (min-width: 768px) {
  .card--hero > img {
    border-radius: 10px;
  }
}
.card--hero .card__content {
  display: block;
}
@media (min-width: 768px) {
  .card--hero .card__content {
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom-left-radius: 10px;
    border-top: 0;
    bottom: 10px;
    left: 10px;
    position: absolute;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .card--hero .card__content {
    width: 70%;
  }
}
.card--hero .card__content img {
  margin-bottom: 0.5rem;
  max-width: 15rem;
}
.careers--list {
  background-color: #fff;
  padding: 5rem 1.5rem;
}
.careers--list h2 {
  color: #000;
  font-weight: 600;
  font-size: 6rem;
  line-height: 5.4rem;
  margin-top: 4rem;
}
@media (max-width: 767px) {
  .careers--list h2 {
    font-size: 4.2rem;
  }
}
.careers--list .job--lists {
  margin-bottom: 4rem;
}
.careers--list .job--lists ul {
  list-style: none;
}
.careers--list .job--lists ul li:first-child {
  font-weight: 700;
  padding: 1.5rem 0;
}
.careers--list .job--lists ul li:first-child:hover {
  background: none;
}
.careers--list .job--lists ul li {
  border-bottom: 1px solid #D5D5D5;
  color: #000;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 5.1rem;
  padding: 1.5rem 1.5rem;
}
@media (max-width: 767px) {
  .careers--list .job--lists ul li {
    position: relative;
  }
}
.careers--list .job--lists ul li a {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .careers--list .job--lists ul li a {
    max-width: 80%;
  }
}
.careers--list .job--lists ul li a .icon {
  margin: 1.5rem 0;
}
@media (max-width: 767px) {
  .careers--list .job--lists ul li a .icon {
    position: absolute;
    right: 1rem;
    bottom: 0.9rem;
  }
}
.careers--list .job--lists ul li a .icon svg {
  border: 2px solid #000;
  border-radius: 50%;
  padding: 0.5rem;
}
@media (max-width: 767px) {
  .careers--list .job--lists ul li a .icon {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  .careers--list .job--lists ul li a {
    font-size: 1.8rem;
  }
}
.careers--list .job--lists ul li:hover {
  background: #00e2ad;
}
.careers--list .job--lists ul li:hover a .icon svg {
  background: #000;
  fill: #FFFDFD;
}
@media (max-width: 767px) {
  .careers--list .job--lists ul li {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .careers--list .job--lists ul {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .careers--list .col-sm-12 {
    width: 100%;
  }
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.glide--marquee {
  pointer-events: none;
}
.glide--marquee .glide__slide {
  align-self: stretch;
  display: flex;
  height: auto;
}
.glide--hero .glide__slide {
  display: flex;
  height: auto;
  transform: scale(0.95);
  transition: transform 0.4s ease-in-out;
}
.glide--hero .glide__slide--active {
  transform: scale(1);
}
.glide--hero .card {
  display: flex;
  flex-direction: column;
}
.glide--hero .card__content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.glide--hero .card .cta {
  margin-top: auto;
}

.career--single .background-white {
  padding-bottom: 4rem;
}
.career--single .jd--block {
  margin: 5rem 0 5rem;
  padding-right: 15%;
}
.career--single .jd--block h2 {
  font-size: 2.4rem;
  margin-bottom: 3rem;
}
.career--single .jd--block p {
  font-size: 2rem;
}
.career--single .jd--block p a:hover {
  color: #00e0ad;
}
.career--single .jd--block ul {
  list-style: none;
}
.career--single .jd--block ul li {
  font-size: 1.8rem;
  line-height: 3rem;
  padding: 1rem 0;
}
.career--single .jd--block ul li::before {
  content: "•";
  color: #f963bd;
  display: inline-block;
  font-weight: bold;
  margin-left: -1em;
  width: 1em;
  font-size: 1.8rem;
}
@media (max-width: 991px) {
  .career--single .jd--block {
    padding-right: inherit;
  }
}
.career--single .apply--btn a {
  align-items: center;
  background-color: #000;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 2rem;
  padding: 1.5rem 5rem;
  text-align: center;
  transition: 0.3s;
}
.career--single .apply--btn a:hover {
  background: #fff;
  color: #000;
}
.career--single .single--job--box {
  background: #00e2ad;
  min-height: 200px;
  margin-top: -70%;
  overflow: hidden;
  padding: 1rem 2rem;
  position: sticky;
  top: 5%;
}
.career--single .single--job--box ul {
  list-style: none;
  padding: 0 2rem;
}
.career--single .single--job--box ul li {
  border-bottom: 1px solid #3AB48F;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  padding: 2rem 1rem;
}
.career--single .single--job--box ul li p + p {
  margin-top: inherit;
}
.career--single .single--job--box ul li h3 {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.9rem;
}
.career--single .single--job--box ul li:last-child {
  border-bottom: none;
}
.career--single .single--job--box .apply--btn {
  margin: 4rem auto;
  text-align: center;
}
.career--single .single--job--box .apply--btn a {
  padding: 3rem 12rem;
}
@media (max-width: 1399px) {
  .career--single .single--job--box .apply--btn a {
    padding: 1.5rem 5rem;
  }
}
@media (max-width: 1199px) {
  .career--single .single--job--box {
    margin-top: 4rem;
  }
}
@media (max-width: 991px) {
  .career--single .single--job--box {
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .career--single .article-hero {
    background-color: #000;
    padding-bottom: 5rem;
  }
}

.contact {
  color: #003428;
}
.contact-link {
  display: block;
  padding-block: 7rem 4rem;
  text-align: center;
}
@media (min-width: 768px) {
  .contact-link {
    align-items: center;
    display: flex;
    height: 70vh;
    min-height: 60rem;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .contact-grid {
    display: grid;
    grid-template-columns: 1fr 30%;
    grid-template-rows: auto 1fr;
  }
}
.contact-title {
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  .contact-title {
    font-size: 1.8rem;
  }
}
.contact-invited {
  font-size: 10rem;
  font-weight: 700;
  line-height: 1;
  margin-block: 0 2rem;
}
@supports (font-size: clamp(6rem, 15vw, 19rem)) {
  .contact-invited {
    font-size: clamp(7.7rem, 15vw, 20rem);
  }
}
@media (min-width: 768px) {
  .contact-invited {
    grid-column: 1/span 2;
    grid-row: 2;
    margin-block: 0;
    z-index: 2;
  }
}
.contact-image {
  box-shadow: -3rem -3rem rgba(255, 255, 255, 0.38);
  margin-left: 3rem;
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .contact-image {
    box-shadow: -1.8rem -1.8rem rgba(255, 255, 255, 0.38);
    grid-column: 2;
    grid-row: 1/span 2;
    margin: 0;
  }
}
.contact-image img {
  width: 100%;
}

.content-panel + .content-panel {
  margin-top: 6rem;
}
@media (min-width: 768px) {
  .content-panel + .content-panel {
    margin-top: 11rem;
  }
}
.content-panel__img {
  width: 100%;
}
.content-panel__content .title-small {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .content-panel__content {
    padding-inline: 10%;
  }
}
@media (min-width: 992px) {
  .content-panel__content {
    padding-inline: 20%;
  }
}

.modal {
  bottom: 0;
  color: #000;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}
.modal:not(.is-open) {
  display: none;
}
.modal__overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.modal__container {
  background: #fff;
  border-radius: 20px;
  display: block;
  height: 40vh;
  width: 40vw;
}
.modal--full-screen .modal__container {
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.contact-form__description {
  height: 130px;
  width: 100%;
  resize: none;
}

.row.justify-content-between.align-items-center {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .row.justify-content-between.align-items-center {
    flex-direction: row;
  }
}

.hero {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 8rem;
}
@media (min-width: 768px) {
  .hero:not(.no-bottom-padding) {
    padding-bottom: 12rem;
  }
}
.hero-banner {
  position: relative;
  width: 100%;
}
.hero-main-image {
  background-color: #000;
  width: 45%;
  margin-inline: auto;
}
.hero-main-image img {
  opacity: 0;
  transition: opacity 0.6s ease-in-out 0.4s;
  width: 100%;
}
.fade-in .hero-main-image img {
  opacity: 0.6;
}
.hero-main-image img.fade-full {
  opacity: 1;
}
.hero-main-text {
  left: 0;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  width: 100%;
}
.hero-main-text .hero-title:not(.is-active) {
  opacity: 0;
}
.hero-subtitle {
  margin-top: 1rem;
}
.hero-subtitle p {
  margin-inline: auto;
  max-width: 47rem;
}
.hero-backing-images {
  height: 70%;
  inset: 0;
  position: absolute;
  margin-block: auto;
  z-index: -1;
}
.hero-backing-images .images-row {
  display: flex;
  inset: 0;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  position: absolute;
}
.fade-in .hero-backing-images .images-row {
  opacity: 1;
}
.hero-backing-images .second-row {
  margin-inline: auto;
  width: 75%;
  transition-delay: 0.6s;
}
.hero-backing-images .second-row .hero-image {
  flex: 0 0 42%;
}
.hero-backing-images .second-row img {
  opacity: 0.55;
}
.hero-backing-images .third-row {
  height: 70%;
  margin: auto;
  transition-delay: 0.8s;
}
.hero-backing-images .third-row .hero-image {
  flex: 0 0 22%;
}
.hero-backing-images .third-row img {
  opacity: 0.5;
}
.hero-backing-images .hero-image {
  background-color: #000;
}

.meet-the-team {
  padding-block: 75vw 10rem;
}
@media (min-width: 768px) {
  .meet-the-team {
    padding-block: 25rem;
  }
}
.meet-the-team-title {
  padding-block: 4rem;
}
@media (max-width: 991px) {
  .meet-the-team-title {
    max-width: 60rem;
  }
}
.meet-the-team-title .cta {
  margin-top: 2rem;
}

.team {
  width: 100%;
  position: relative;
}
@media (max-width: 991px) {
  .team {
    height: 30rem;
  }
}
.team-member {
  isolation: isolate;
  position: absolute;
}
@media (min-width: 768px) {
  .team-member {
    opacity: 0.5;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    transition-delay: 0.2s;
  }
}
.team-member-1 {
  top: 0;
  left: 10%;
}
.team-member-2 {
  top: 40%;
  left: 0;
}
@media (min-width: 768px) {
  .team-member-2 {
    top: 55%;
  }
}
.team-member-3 {
  top: 75%;
  left: 10%;
}
@media (min-width: 768px) {
  .team-member-3 {
    top: 30%;
  }
}
@media (min-width: 768px) {
  .team-member-3 {
    left: 30%;
  }
}
.team-member-4 {
  top: 20%;
  left: 40%;
}
@media (min-width: 768px) {
  .team-member-4 {
    top: 85%;
  }
}
@media (min-width: 768px) {
  .team-member-4 {
    left: 30%;
  }
}
.team-member-5 {
  top: 55%;
  left: 40%;
}
@media (min-width: 768px) {
  .team-member-5 {
    top: 8%;
  }
}
@media (min-width: 768px) {
  .team-member-5 {
    left: 60%;
  }
}
.team-member-6 {
  top: 0;
  right: 10%;
}
@media (min-width: 768px) {
  .team-member-6 {
    top: 55%;
  }
}
@media (min-width: 768px) {
  .team-member-6 {
    right: 35%;
  }
}
.team-member-7 {
  top: 40%;
  right: 0%;
}
@media (min-width: 768px) {
  .team-member-7 {
    top: 30%;
  }
}
@media (min-width: 768px) {
  .team-member-7 {
    right: 10%;
  }
}
.team-member-8 {
  bottom: 0;
  right: 10%;
}
@media (min-width: 1200px) {
  .team-member-8 {
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .team-member-8 {
    right: 5%;
  }
}
.team-member:hover {
  opacity: 1;
}
.team-member-image {
  position: relative;
  width: 65px;
}
@media (min-width: 768px) {
  .team-member-image::before {
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 110%;
    opacity: 0;
    position: absolute;
    right: 0;
    transform: scale(0.7);
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    width: 110%;
    z-index: -1;
  }
  .cresent-pink.team-member-image::before {
    background-color: #f963bd;
  }

  .cresent-green.team-member-image::before {
    background-color: #00e2ad;
  }

  .cresent-white.team-member-image::before {
    background-color: #fff;
  }

  .cresent-orange.team-member-image::before {
    background-color: #ffb800;
  }

  .cresent-teal.team-member-image::before {
    background-color: #0cecf7;
  }

  .team-member:hover .team-member-image::before {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.2s;
  }
}
.team-member-image img {
  border-radius: 50%;
}
.team-member-image i {
  display: none;
}
@media (min-width: 768px) {
  .team-member-image i {
    display: block;
    bottom: 0;
    font-style: normal;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    transition-delay: 0.2s;
    transform: translate(-50%, -50%);
  }
  .team-member:hover .team-member-image i {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.team-member-name {
  display: none;
}
@media (min-width: 768px) {
  .team-member-name {
    color: #fff;
    display: block;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    transform: translate(-50%, 0);
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    top: 100%;
    width: max-content;
  }
  .team-member:hover .team-member-name {
    opacity: 1;
    transform: translate(-50%, 1rem);
    transition-delay: 0.2s;
  }
}
.team-member-title {
  color: #808080;
  display: block;
}

.partner-banner {
  background: linear-gradient(0deg, #000 40%, #fff 40%);
}
.partner-banner-container {
  isolation: isolate;
  position: relative;
}
@supports (font-size: clamp(6rem, 15vw, 19rem)) {
  .partner-banner-container::after {
    bottom: 0;
    color: rgba(0, 52, 40, 0.04);
    content: attr(data-shadow-text);
    font-size: clamp(6rem, 16vw, 23rem);
    font-family: "Proxima Nova", helvetica, arial, sans-serif;
    font-weight: 700;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translate(-50%, 30%);
    width: 100%;
    z-index: -1;
  }
}
.partner-banner-content {
  padding: 0 3rem 8rem;
}
@media (min-width: 768px) {
  .partner-banner-content {
    padding: 3rem 0;
  }
}
.partner-banner img {
  width: 100%;
}
.partner-banner p {
  margin-block: 2.5rem 4rem;
}
@media (min-width: 1200px) {
  .partner-banner p {
    padding-right: 4rem;
  }
}

.services {
  isolation: isolate;
}
@media (min-width: 768px) {
  .services {
    overflow: hidden;
  }
}
.services-slides {
  display: grid;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.services-slides .container {
  display: flex;
}
.services-slides .slide {
  display: flex;
  grid-column: 1;
  grid-row: 1;
  pointer-events: none;
  position: relative;
  transition: opacity 0.6s;
  width: 100% !important;
  z-index: 1;
}
.services-slides .slide.active-slide {
  pointer-events: auto;
  z-index: 2;
}
.services-slides .slide-row {
  position: relative;
}
@media (min-width: 768px) {
  .services-slides .slide-row {
    display: flex;
    width: 100%;
  }
}
.services-slides .slide-content {
  padding: 5.5rem 2rem 0;
}
@media (min-width: 768px) {
  .services-slides .slide-content {
    margin-right: 5%;
    padding-inline: 0;
    width: 85%;
  }
}
@media (min-width: 1200px) {
  .services-slides .slide-content {
    width: 65%;
  }
}
.services-slides .slide-image {
  width: 100%;
}
@media (max-width: 767px) {
  .services-slides .slide-image {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateY(50%);
  }
}
@media screen and (max-width: 767px) and (max-height: 600px) {
  .services-slides .slide-image {
    transform: translateY(90%);
  }
}
@media (min-width: 768px) {
  .services-slides .slide-image {
    position: relative;
  }
}
.services-slides .slide-image img {
  width: 100%;
}
@media (min-width: 768px) {
  .services-slides .slide-image img {
    left: 0;
    height: 100%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }
}
.services-background {
  background-color: #fff;
  inset: 1rem 0 0 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
@media (min-width: 768px) {
  .services-background {
    inset: 5vh 15vh 5vh 0;
  }
}
@media screen and (min-width: 768px) and (max-height: 750px) {
  .services-background {
    inset-block: 4rem;
  }
}
@media (min-width: 992px) {
  .services-background {
    right: 10vw;
  }
}
@media (min-width: 1200px) {
  .services-background {
    right: 25vw;
  }
}
.services-background .background {
  inset: 0;
  position: absolute;
}
.services-wrapper {
  display: flex;
  height: 100vh;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .services-wrapper {
    background: none;
    padding-block: 15vh;
  }
}
@media screen and (min-width: 768px) and (max-height: 750px) {
  .services-wrapper {
    padding-block: 10rem;
  }
}
.services-carousel {
  display: flex;
  position: relative;
  width: 100%;
}
.services-eyebrow {
  color: #000;
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  margin-bottom: 5px;
}
.services-eyebrow .dash {
  background: currentColor;
  height: 0.2rem;
  margin-inline: 0.8rem;
  width: 3.2rem;
}
.services-quote {
  margin-block: 2rem;
}
.services-quote p {
  font-size: 2.2rem;
}
.services-quote cite {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  margin-top: 2rem;
}
.services-quote img {
  margin-right: 1.3rem;
  max-width: 50px;
}

.logo-marquee-header {
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .logo-marquee-header {
    margin-bottom: 3rem;
  }
}
.logo-card {
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.page-header {
  padding-block: 9rem 3rem;
}
@media (min-width: 768px) {
  .page-header {
    padding-block: 11rem 5rem;
  }
}
.page-header-description {
  margin-top: 2rem;
}
.page-header.shadow-label {
  margin-bottom: 0;
}

.case-study-header h1 {
  font-size: 6rem;
  line-height: 0.9;
}
@supports (font-size: clamp(6rem, 15vw, 19rem)) {
  .case-study-header h1 {
    font-size: clamp(3.2rem, 6vw, 7.6rem);
  }
}
.case-study-header-content {
  align-self: center;
  padding-top: 7rem;
}
@media (min-width: 768px) {
  .case-study-header-content {
    padding-block: 7rem;
  }
}
.case-study-header-image {
  align-self: flex-end;
  width: 100%;
}
.case-study-header-image img {
  width: 100%;
}
.case-study-logo {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}
.case-study-stats {
  color: #000;
  overflow: hidden;
  padding-inline: 1rem;
}
@media (min-width: 768px) {
  .case-study-stats {
    margin-top: -93px;
    padding-inline: 0;
  }
}
.case-study-stats .container {
  display: flex;
  justify-content: flex-end;
}
.case-study-stats p {
  font-weight: 700;
}
.case-study-stats .stats {
  aspect-ratio: 6/5;
  position: relative;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .case-study-stats .stats {
    width: 600px;
  }
}
.case-study-stats .stat {
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transform: scale(0.6);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
@media (min-width: 768px) {
  .case-study-stats .stat {
    gap: 1rem;
  }
}
.animate.case-study-stats .stat {
  opacity: 1;
  transform: scale(1);
}

.case-study-stats .stat:nth-child(2) {
  transition-delay: 200ms;
}
.case-study-stats .stat:nth-child(3) {
  transition-delay: 300ms;
}
.case-study-stats .stat:nth-child(4) {
  transition-delay: 400ms;
}
.case-study-stats .stat:nth-child(5) {
  transition-delay: 500ms;
}
.case-study-stats .stat:nth-child(6) {
  transition-delay: 600ms;
}
.case-study-stats .pink,
.case-study-stats .green {
  width: 50%;
}
.case-study-stats .pink p,
.case-study-stats .green p {
  font-size: 3.2rem;
}
@media (min-width: 768px) {
  .case-study-stats .pink p,
.case-study-stats .green p {
    font-size: 4.4rem;
  }
}
.case-study-stats .pink {
  background-color: #f963bd;
  left: 0;
  top: 20%;
}
.case-study-stats .green {
  background-color: #00e0ad;
  right: 0;
  top: 0;
}
.case-study-stats .teal {
  bottom: 20%;
  background-color: #0cecf7;
  right: 25%;
  width: 25%;
  z-index: 1;
}
.case-study-stats .teal p {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .case-study-stats .teal p {
    font-size: 2.4rem;
  }
}
.case-study-stats .small {
  overflow: hidden;
  width: 33.333%;
}
.case-study-stats .small img {
  width: 100%;
}
.case-study-stats .image-1 {
  left: 28%;
  top: -1%;
}
.case-study-stats .image-2 {
  bottom: 23%;
  right: -2%;
}
.case-study-stats .image-3 {
  bottom: -3%;
  left: 28%;
}
.case-study-stats .emoji {
  left: -3%;
  bottom: -2%;
}
.case-study-stats small {
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .case-study-stats small {
    font-size: 1.4rem;
  }
}
.case-study-services .title-large {
  margin-bottom: 4rem;
}
.case-study-services .title-small {
  color: #99733a;
}
.case-study-services p a {
  color: #00e0ad;
  text-decoration: underline;
}
.case-study-services p a:hover {
  color: #fff;
}
.case-study-services .technologies-services-block + .technologies-services-block {
  margin-top: 2.2rem;
}
.case-study-services .service-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin-top: 1.5rem;
}
.case-study-services .pill {
  align-items: center;
  border: 0.1rem solid #00e0ad;
  border-radius: 3.2rem;
  color: #00e0ad;
  display: flex;
  font-size: 1.2rem;
  padding: 0.5rem 1.6rem;
}
.case-study-content .title-large {
  margin-bottom: 4rem;
}
.case-study-carousel-header {
  text-align: center;
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .case-study-carousel-header {
    padding-inline: 5rem;
  }
}
@media (min-width: 768px) {
  .case-study-carousel .glide {
    max-width: 75vw;
    margin-inline: auto;
  }
}
@media (min-width: 992px) {
  .case-study-carousel .glide {
    max-width: 81.7rem;
  }
}
.case-study-carousel .glide__arrows {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .case-study-carousel .glide__arrows {
    left: -7rem;
    justify-content: space-between;
    right: -7rem;
    margin-top: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.case-study-carousel .glide__arrows button {
  align-items: center;
  background: none;
  border: 0.2rem solid #00e0ad;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
}
@media (min-width: 768px) {
  .case-study-carousel .glide__arrows button {
    height: 6rem;
    width: 6rem;
  }
}
.case-study-carousel .glide__arrows .previous span {
  transform: rotate(180deg);
}
.case-study-carousel .glide__arrows span {
  font-size: 0;
  width: 1.5rem;
}
@media (min-width: 768px) {
  .case-study-carousel .glide__arrows span {
    width: 2.3rem;
  }
}
.case-study-carousel .glide__arrows svg {
  fill: #00e0ad;
}
.case-study-carousel .glide__pagination {
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  margin-top: 5rem;
}
.case-study-carousel .glide__pagination span {
  border: 0.1rem solid #000;
  border-radius: 50%;
  height: 0.5rem;
  transition: background-color 300ms ease-in-out;
  width: 0.5rem;
}
.case-study-carousel .glide__pagination .glide__bullet--active {
  background-color: #000;
}

.quote-banner blockquote {
  margin-inline: auto;
  max-width: 65.3rem;
  padding-top: 5rem;
}
@media (min-width: 768px) {
  .quote-banner blockquote {
    padding-top: 9rem;
  }
}

.quote-section {
  padding-top: 10rem;
}
@media (min-width: 768px) {
  .quote-section {
    padding-top: 15rem;
  }
}
.quote-section-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .quote-section-content {
    flex-direction: row;
    gap: 5rem;
  }
}
.quote-section-content::before {
  color: #00e0ad;
  content: open-quote;
  font-size: 15rem;
  line-height: 0.5;
  text-align: center;
}
@media (min-width: 768px) {
  .quote-section-content::before {
    font-size: 20rem;
  }
}
.quote-section-content blockquote {
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
}
@supports (font-size: clamp(6rem, 15vw, 19rem)) {
  .quote-section-content blockquote::after {
    align-self: flex-end;
    color: rgba(255, 255, 255, 0.05);
    content: attr(data-shadow-text);
    font-size: clamp(6rem, 15vw, 19rem);
    font-weight: 700;
    line-height: 1;
  }
}
.quote-section-content p {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
}
@media (min-width: 768px) {
  .quote-section-content p {
    font-size: 4.2rem;
  }
}
@media (min-width: 992px) {
  .quote-section-content p {
    max-width: 70%;
  }
}
.quote-section-content cite {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  gap: 1.3rem;
}
.quote-section-content img {
  width: 4.8rem;
}

.image-scale {
  position: relative;
}
.image-scale::after {
  background-color: #fff;
  content: "";
  display: block;
  inset: 50% 0 0;
  position: absolute;
  z-index: -1;
}
.image-scale figure {
  margin-inline: auto;
  transition: width 600ms ease-in-out;
  width: 50%;
}
.animate.image-scale figure {
  width: 100%;
}

.image-scale img {
  width: 100%;
}

.privacy p {
  margin-top: 2rem !important;
}
.privacy h2 {
  font-size: 4rem !important;
  margin-bottom: 2rem;
}
.privacy h3 {
  margin-top: 2rem;
}
.privacy li {
  margin-bottom: 1rem;
}
.privacy td {
  padding: 1.5rem;
}
.privacy li a {
  text-decoration: underline;
  cursor: pointer !important;
}
.privacy a:hover {
  color: #15b587;
}

.global-header {
  color: #fff;
  display: flex;
  flex-direction: column;
  mix-blend-mode: exclusion;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.theme--light .global-header {
  color: #000;
}

.global-header.menu-is-open {
  background: #000;
  mix-blend-mode: unset;
}
.global-header .container {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
  position: relative;
  width: 100%;
}
.global-header__logo {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 35px;
}
.global-header__logo svg {
  fill: currentColor;
  width: 100%;
}
.global-header__nav-menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}
.global-header__nav-menu:not(.menu-is-open) {
  display: none;
}

.menu-button {
  align-items: center;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 40px;
}
.menu-button * {
  pointer-events: none !important;
}
.menu-button__icon {
  color: inherit;
  display: block;
  width: 100%;
}
.menu-button__icon::before, .menu-button__icon::after {
  background-color: currentColor;
  border-radius: 10px;
  content: "";
  display: block;
  height: 3px;
  position: relative;
  transition: opacity 0.2s ease-in, transform 0.2s ease-in, width 0.2s ease-in;
}
.menu-button__icon::before {
  margin-bottom: 5px;
  width: 40px;
}
.menu-button__icon::after {
  margin-top: 5px;
  width: 30px;
}
.menu-is-open .menu-button__icon::before, .menu-is-open .menu-button__icon::after {
  margin: 0 auto;
}
.menu-is-open .menu-button__icon::before {
  transform: translateY(50%) rotate(45deg);
  width: 30px;
}
.menu-is-open .menu-button__icon::after {
  transform: translateY(-50%) rotate(-45deg);
  width: 30px;
}
.menu-button:active:not(.menu-is-open) .menu-button__icon::before, .menu-button:active:not(.menu-is-open) .menu-button__icon::after, .menu-button:focus:not(.menu-is-open) .menu-button__icon::before, .menu-button:focus:not(.menu-is-open) .menu-button__icon::after, .menu-button:hover:not(.menu-is-open) .menu-button__icon::before, .menu-button:hover:not(.menu-is-open) .menu-button__icon::after {
  opacity: 0.7;
}
.menu-button:active:not(.menu-is-open) .menu-button__icon::after, .menu-button:focus:not(.menu-is-open) .menu-button__icon::after, .menu-button:hover:not(.menu-is-open) .menu-button__icon::after {
  width: 40px;
}

.site-nav {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
}
@media (max-width: 991px) {
  .site-nav {
    max-height: 80vh;
  }
}
.site-nav__menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.site-nav__item {
  text-align: center;
  opacity: 0;
  transform: translateY(100%);
  width: 100%;
}
.site-nav__item a {
  color: #fff;
  display: inline-block;
  font-size: 6rem;
  font-weight: 600;
  padding-inline: 4rem;
  transition: opacity 0.2s;
}
.site-nav__item a:active, .site-nav__item a:focus, .site-nav__item a:hover {
  opacity: 0.5;
}

.page-footer {
  background: #00e0ad;
  bottom: 0;
  color: #000;
  position: sticky;
  z-index: -1;
}
.page-footer.footer--alt {
  background: #000;
  color: #fff;
}

.global-footer {
  font-size: 1.4rem;
  line-height: 1.125;
  padding: 0 2rem;
}
.global-footer .container {
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .global-footer .container {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer--alt .global-footer .container {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.global-footer .copyright {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .global-footer .copyright {
    margin-bottom: 0;
  }
}
.global-footer .footer-menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}
.global-footer .footer-menu li {
  list-style: none;
  margin: 0;
  padding: 0 2rem;
}
.global-footer .footer-menu a {
  color: inherit;
  display: block;
  padding: 0.5rem;
  text-decoration: none !important;
}
.global-footer .footer-menu .icon {
  display: block;
}
.global-footer .footer-menu .icon svg {
  display: block;
  fill: currentColor;
  height: 15px;
  width: 15px;
}

.page {
  isolation: isolate;
}
.page-content {
  background: #000;
  isolation: isolate;
  min-height: 100vh;
}

.thank-you-section {
  background: url(~../images/confetti-overlay.gif) top center/cover no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thank-you-section .container-md {
  max-width: 95rem;
}

.flex, .cta-register, .rsvp-cta, .summit-details .col-text {
  align-items: center;
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr;
}

.column + .column {
  padding-top: 2.5rem;
}

.summit-details {
  overflow-x: hidden;
}
.summit-details .container {
  max-width: 120rem;
}
.summit-details .hero {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.summit-details .hero h1 {
  margin-bottom: 2.5rem;
}
.summit-details .summit-logos {
  align-items: center;
  display: flex;
  margin-bottom: 2.5rem;
}
.summit-details .title-text-container {
  padding: 0;
}
.summit-details .title-text-container p {
  margin: 0 0 2.5rem;
}

.pink-circle {
  float: left;
  height: 2rem;
  line-height: normal;
  margin-right: 1rem;
}

.underline-link {
  text-decoration: underline;
}

.bold-link-green {
  color: #00e0ad;
}

.rsvp-section {
  margin-top: 0;
}
.rsvp-section__text h3 {
  font-size: 3.8rem;
}
.rsvp-section__image {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rsvp-section__image .image-wrapper {
  height: 40rem;
  position: relative;
  width: 40rem;
}
.rsvp-section__image .badge {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
}
.rsvp-section__image .badge.sparkle {
  left: -4rem;
  top: 8rem;
}
.rsvp-section__image .badge.star {
  right: 3rem;
  top: -1rem;
}
.rsvp-section__image .badge.star .sparkles {
  position: absolute;
  right: 27%;
  top: 33%;
}
.rsvp-section .row {
  align-items: center;
  height: auto;
  padding: 2.5rem;
}

.rsvp-cta {
  color: #fff;
  font-weight: bold;
  justify-content: center;
  margin-top: 2rem;
  width: auto;
}
.rsvp-cta span {
  margin-right: 0.5rem;
}
.rsvp-cta .icon {
  color: #fff;
  width: 4rem;
}
.rsvp-cta .icon circle {
  stroke: #fff;
}
.rsvp-cta .icon path {
  stroke: #000;
}
.rsvp-cta:hover {
  text-decoration: underline;
}

.section-block.sessions {
  padding-left: 4rem;
  padding-right: 4rem;
}

.session-heading {
  margin-bottom: 5rem;
}

.session-details {
  border-top: 1px solid #3e3e3e;
  padding: 5rem 0;
  transition: all 0.3s ease-in-out;
}
.session-details:hover {
  color: #fff;
  transform: scale(1.025);
}
.session-details__header {
  font-size: 20px;
  font-weight: bold;
  padding: 0 0 1rem;
}
.session-details__header span {
  color: #f963bd;
}
.session-details .left {
  padding-right: 5rem;
}
.session-details h3 {
  color: #fff;
  font-size: 3rem;
}
.session-details h4 {
  color: #fff;
  padding: 2rem 0 1rem;
}
.session-details ul {
  margin: 0;
  padding-left: 2rem;
}
.session-details a.speaker {
  color: #00e0ad;
  font-weight: bold;
  text-decoration: underline;
}
.session-details a.speaker:hover {
  color: #007a5e;
  text-decoration: none;
}

.cta-register {
  color: #fff;
  font-weight: bold;
  height: 3rem;
  justify-content: flex-start;
  margin-top: 3rem;
}
.cta-register .icon {
  color: #fff;
  margin-left: 1rem;
  width: 2.5rem;
}
.cta-register .icon circle {
  stroke: #fff;
}
.cta-register .icon path {
  stroke: #000;
}
.cta-register:hover {
  text-decoration: underline;
}

.rsvp-heading {
  font-weight: bold;
  margin: 20px 0 24px 0;
}

.link-list {
  margin-left: 0;
}

.link-container {
  border-top: 1px solid #c4c4c4;
}

.rsvp-link {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: normal;
  height: 8rem;
  justify-content: space-between;
}

.link-container:hover .icon-button {
  transform: scale(1.25);
}

.link-container:hover .icon-button svg {
  fill: #f8f8f8;
}

.link-container .text {
  color: #808080;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.link-container:hover .text {
  color: #000;
}

.rsvp-link .icon-button {
  align-items: center;
  display: flex;
  transform-origin: 50% 50%;
  transition: all 0.3s ease-in-out;
}

.responsive-img {
  height: auto;
  max-width: 1340px;
  width: 100%;
}

.contact {
  display: none;
}

.footer-spacer {
  background: #00e2ad;
  height: 3rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .column + .column {
    padding-top: 0;
  }

  .link-list {
    margin-left: 4rem;
  }

  .title-text-container {
    padding-left: 5rem;
    padding-right: 10rem;
  }

  .title-img2 {
    margin-top: -120px;
  }

  .section-block.booth-info {
    padding-bottom: 33rem;
  }

  .rsvp-section {
    margin-top: -28rem;
  }
  .rsvp-section .row {
    padding: 10rem;
  }
  .rsvp-section .rsvp-cta {
    justify-content: flex-start;
  }

  .session-text {
    padding-right: 75px;
  }
}
@media (max-width: 767px) {
  .social-icons {
    margin-bottom: 0;
  }

  .rsvp-heading {
    margin: 2rem 1rem;
  }
}
.summit-rsvp__form {
  /* Form Overrides */
}
.summit-rsvp__form.form-container {
  background-color: #00e2ad;
  border-radius: 0.5rem;
  padding: 3rem;
  padding-bottom: 2rem;
}
.summit-rsvp__form.form-container p {
  font-size: 14px;
  margin: 2rem 0;
}
.summit-rsvp__form .form-control {
  border-radius: 0;
  color: #000;
  height: 5rem;
}
.summit-rsvp__form .more-info:hover,
.summit-rsvp__form #submit-button:hover {
  color: #00e2ad;
}
.summit-rsvp .title-text-container {
  padding-bottom: 125px;
  padding-top: 172px;
}

.title-text-container .eyebrow {
  margin-bottom: 2rem;
}

.event-details-container h3 {
  margin: 4rem 0 2rem;
}

.event-details-container li {
  margin: 1rem 0;
}

.responsive-img {
  height: auto;
  max-width: 1340px;
  width: 100%;
}

.contact {
  display: none;
}

.form-group {
  margin-bottom: 3rem;
}

input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
  background: #fff;
  border: 0;
  color: inherit;
  font-size: 14px;
  font-family: inherit;
  margin: 0;
  outline: none;
  padding: 0 1rem;
  -webkit-text-fill-color: #000 !important;
}

.footer-spacer {
  background: #00e2ad;
  height: 3rem;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

button.submit {
  background-color: #000;
  border-radius: 0;
  color: #fff;
  padding: 2rem 1rem;
  width: 100%;
}

button.more-info {
  margin-bottom: 3rem;
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

@media (min-width: 768px) {
  .title-text-container {
    padding-left: 5rem;
  }

  .event-details-container {
    padding: 0 5rem;
  }

  .form-container {
    float: right;
    max-width: 385px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -215px;
  }
}
/***** Spinner CSS ****/
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  border-radius: 50%;
  border-right-color: transparent;
  border: 0.25em solid currentColor;
  display: inline-block;
  height: 2rem;
  vertical-align: text-bottom;
  width: 2rem;
}

.spinner-border-sm {
  border-width: 0.2em;
  height: 1.5rem;
  margin-right: 3px;
  width: 1.5rem;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: none;
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: none;
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: text-bottom;
  width: 2rem;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}