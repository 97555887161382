@use 'sass:map';
@use 'sass:string';
@use 'sass:list';

@function str-replace($string, $search, $replace: "") {
	$index: string.index($string, $search);
	@if $index {
		@return string.slice($string, 1, $index - 1) + $replace + str-replace(string.slice($string, $index + string.length($search)), $search, $replace);
	}
	@return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff woff2 ttf eot) {
	$src: null;
	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);
	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);
	@each $ext in $exts {
		$extmod: if(map.has-key($extmods, $ext), $ext + map.get($extmods, $ext), $ext);
		$format: if(map.has-key($formats, $ext), map.get($formats, $ext), $ext);
		$url: '#{$path}.#{$extmod}';
		$src: list.append($src, url($url) format('#{$format}'), $separator: comma);
	}
	@font-face {
		font: {
			display: block;
			family: '#{$name}';
			style: $style;
			weight: $weight;
		}
		src: $src;
	}
}
