@use "0_utility/mixins/media-query";
@use "0_utility/variables/colors";
@use "0_utility/variables/file-path";
@use "0_utility/variables/spacing";

.page {
	isolation: isolate;
	&-content {
		background: colors.$black;
		isolation: isolate;
		min-height: 100vh;
	}
}
.thank-you-section {
	background: url(#{file-path.$images}confetti-overlay.gif) top center/cover
		no-repeat;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	.container-md {
		max-width: 95rem;
	}
}
