@use '../0_utility/mixins/media-query';
@use '../0_utility/variables/spacing';
@use 'sass:meta';
@use 'sass:map';

.wrapper {
	@if meta.global-variable-exists(multiple-gaps, $module: 'spacing') {
		@each $breakpoint in map.keys(spacing.$multiple-gaps) {
			@include media-query.up($breakpoint) {
				$gutter: map.get(spacing.$multiple-gaps, $breakpoint);
				padding-inline: $gutter;
			}
		}
	} @else {
		padding-inline: spacing.$gutterWidth;
	}
}
