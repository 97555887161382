@use '0_utility/mixins/media-query';
@use '0_utility/variables/font-weights';
@use '0_utility/variables/colors';

.bottom-ctas {
	text-align: center;
	&-links {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		margin-top: 7.5rem;
		@include media-query.up(md) {
			flex-direction: row;
		}
		a {
			flex: 1;
			position: relative;
		}
		img {
			width: 100%;
		}
		.cta {
			&-info {
				align-items: center;
				display: flex;
				font-weight: font-weights.$medium;
				flex-direction: column;
				height: 100%;
				inset: 0;
				margin-inline: auto;
				max-width: 48.6rem;
				padding: max(5vw, 10rem) 2rem 2rem;
				position: absolute;
				@include media-query.up(md) {
					padding-top: 35%;
				}
				.cta {
					margin-top: auto;
				}
			}
			&-type {
				align-items: center;
				display: flex;
				font-size: 1.4rem;
				gap: .5rem;
			}
			&-title {
				font-size: 3.2rem;
				line-height: 1.2;
				@include media-query.up(md) {
					font-size: 4rem;
				}
			}
		}
		.divider {
			background-color: colors.$white;
			height: 0.1rem;
			width: 3.5rem;
		}
	}
}