// Max width used for container class
$max-width: 132rem;

// Used to create multiple max width classes
// Example .container-xs, .container-sm, ect.
// Note: default does not get added to the end and will be .container
// Remove if only one max width is needed
$multiple: (
	xs: 37rem,
	sm: 50rem,
	md: 89.4rem,
	standard: 100rem,
	default: $max-width,
	xl: 150rem,
);
