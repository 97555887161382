@use '../0_utility/variables/colors';
@use 'sass:meta';

@if meta.global-variable-exists(backgroundColors, $module: 'colors') {
	@each $name, $color in colors.$backgroundColors {
		.bg-#{$name} {
			background-color: $color;
		}
	}
}

.bg-black-to-white {
	background: linear-gradient(to bottom, colors.$black 50%, colors.$white 50%);
}