@use '0_utility/mixins/media-query';

.quote-banner {
	blockquote {
		margin-inline: auto;
		max-width: 65.3rem;
		padding-top: 5rem;
		@include media-query.up(md) {
			padding-top: 9rem;
		}
	}
}