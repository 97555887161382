@use '0_utility/variables/colors';
@use '0_utility/variables/spacing';
@use '0_utility/variables/font-family';

form {
	border: 0;
	display: block;
	margin: 0;
	padding: 0;

	p {
		font-size: inherit;
		line-height: inherit;
	}
}

input {

	&:not([type=button], [type=submit], [type=checkbox], [type=radio]) {
		background: none;
		border: 0;
		border-bottom: 1px solid colors.$grayLight;
		color: inherit;
		font-size: 0.7em;
		font-family: inherit;
		margin: 0;
		outline: none;
		padding: 0 spacing.$xs;
	}
	&:-webkit-autofill {
		&,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			transition: background-color 5000s;
			-webkit-text-fill-color: #fff !important;
		}
	}
}

textarea {
	background: none;
	border: 1px solid colors.$grayLight;
	color: inherit;
	font: {
		size: 0.7em;
		family: font-family.$default;
	}
	margin: 0;
	outline: none;
	padding: spacing.$sm;
}

label {
	color: transparent;
	display: block;
	height: 0;
	font-size: 0;
	padding: 0;
	margin: 0;
	border: 0;
	background: none;
	outline: none;
	width: 0;
}

.contact-form {
	margin-bottom: spacing.$xl;
}

.inline-form {
	font-size: 3rem;
	line-height: 2;

	.form-field {
		display: inline-block;

		&--block {
			display: block;
		}
	}

	input {

		&:not([type=button], [type=submit], [type=checkbox], [type=radio]) {
			width: 25rem;
		}
	}

	textarea {
		vertical-align: text-top;
	}
	.button {
		margin-inline: auto;
	}
	.submit-button__icon {
		font-size: 4rem;
		margin-bottom: 1.5rem;
		transform: rotate(-45deg);
	}
}

.form-field-group {
	& + & {
		margin-top: spacing.$lg;
	}
}

.form-field {
	display: block;
	max-width: 100%;
	position: relative;

	&.has-error {

		.form-control {

			&:not([type=button], [type=submit], [type=checkbox], [type=radio]) {
				border-color: colors.$red;
			}
		}
	}
}

.form-control {
	max-width: 100%;
}

.field-hint {
	color: colors.$grayLight;
	font-size: 1.2rem;
	left: 0;
	position: absolute;
	top: 100%;
	text-align: center;
	transform: translateY(-50%);
	width: 100%;

	.has-error & {
		color: colors.$red;
	}
}
